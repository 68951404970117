import React, { Component } from "react"
import "../../css/ProductList.css"
import { Link } from "react-router-dom"
import MLTexts from "../../MLTexts"
const ml = MLTexts.getInstance();

export default class ProductInList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            redirect: false,
            data: {}
        }
    }

    render() {
        return (            
            <Link className="clear" key={this.props.data._id} to={"/product" + "/" + this.props.data._id}>

            <div className="product-list-row">

            <div className="product-list-row-image-container"><img src={this.props.data.image} /></div>
            <div className="product-list-row-inf-container">
                <div className="product-list-title">{this.props.data.title}</div>
                <div className="product-list-properties"> {/*Automatycznie z propsow i mapa header z nazwami stan etc.*/}
                    <div>{ml.getText("Stan")}:</div><div>{this.props.data.properties.condition || ""}</div>
                    <div>{ml.getText("Marka")}:</div><div>{this.props.data.properties.brand || ""}</div>
                    <div>{ml.getText("Model")}:</div><div>{this.props.data.properties.model || ""}</div>
                    <div>{ml.getText("Numer katalogowy")}:</div><div>{this.props.data.name || ""}</div>
                </div>

                <div className="product-list-price" >{ this.props.data.properties.price !== undefined ? (Math.round(this.props.data.properties.price * 100) / 100).toFixed(2) + " zł": "" }</div>
                <div className="product-list-price-netto" >{ this.props.data.properties.price !== undefined ? (Math.round(this.props.data.properties.price * 100) / (100 + (this.props.data.properties.tax != undefined ? this.props.data.properties.tax: 0)) ).toFixed(2) + " zł": "" }<div>{ this.props.data.properties.price !== undefined ? "netto" : ""}</div></div>

            </div>
           
            </div>
            </Link>
        )
    }

}

