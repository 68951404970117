import List from "../Admin/AdminList"
import axios from "axios"
import { SERVER_HOST } from "../../config/global_constants"
import Table from "../Table"
import "../../css/Cart.css"
import MLTexts from "../../MLTexts"
const ml = MLTexts.getInstance();

class Cart extends List {

    constructor() {
        super();
        let co = this;
        this.state = {
            data: [],
            title: ml.getText("Koszyk"),
            header: [
                { name: "image", title: "", field: "photos", compile: true, className: "tableRowImage" },
                { name: "title", title: ml.getText("Produkt"), field: "title" },
                { name: "price", title: ml.getText("Cena"), field: "price" },
                {
                    name: "quantityEditable", title: ml.getText("Ilość"), field: "quantityEditable", compile: true, className: "cart-quantity", onClick: (e) => {
                        alert(e);
                    }
                },
                { name: "sum", title: ml.getText("Łącznie"), field: "sum" }
            ],
            summary: "",
            fieldToUse: {
                "product.properties": ["price"],
                "product": ["_id", "photos", "title"],

            },
            convertValue: {
                "photos": (value) => { return value = value[0] ? `<img src="${SERVER_HOST}/products/photo/${value[0].filename}"/>` : `<img src="${SERVER_HOST}/products/photo/default-product.gif"/>` },
                "sum": (value, obj) => { return value = (obj.quantity * obj.price).toFixed(2) },
                "price": (value) => { return value = value.toFixed(2) },
                "quantityEditable": (value, obj) => { return `<input type="number" id="fname" name="fname" value="${obj.quantity}"/> ` },

            },
            newFields: {
                sum: [{}],
                "quantityEditable": [{}]
            },
            sort: {},

            rowButtons: [
                {
                    title: ml.getText("Usuń"), id: "delete", to: "delete", className: "tableButton red", onClick: (e) => {
                        confirm(ml.getText("Czy na pewno chcesz usunąć?")) ? null : e.preventDefault();
                    }
                }
            ],
            tableButtons: [
                { title: ml.getText("Realizuj zamówienie"), id: "order", to: "/order", className: "tableButton" },
                { title: ml.getText("Kontynuuj zakupy"), id: "continue", to: "", className: "tableButton", onClick(e){
                    e.preventDefault();                    
                    co.props.navigate(-2);
                } }
            ],
        }
    }

    componentDidMount() {
        let url = `${SERVER_HOST}/cart`;
        localStorage.cart != undefined ? url += `/${localStorage.cart}` : null;
        axios.get(url).then(res => {
            res.data["_id"] != undefined ? localStorage.cart = res.data["_id"] : null;
            let summary = `<strong>${ml.getText("Łącznie")}:</strong> <div>${res.data.priceSum.toFixed(2)}</div> <br/> <strong>${ml.getText("VAT")}</strong>: <div>${res.data.tax.toFixed(2)}</div>`;
            this.setState({ data: this.convertValues(res.data.products), summary: summary })
        }).catch(e => {
            delete localStorage.cart;            
        })
    }

    handleRowClick = (e, data, field) => {
        e._reactName == "onChange" && field == "quantityEditable" ?
            this.props.navigate(`edit/${data.product._id}/${e.target.value}`)
            : null;
        field == "photos" ? this.props.navigate(`/product/${data._id}`) : null;
    }

    render() {
        let tableButtons = this.state.tableButtons;
        this.state.data.length == 0 ? tableButtons = [] : null;
        return (
            <div>
                <h3 className="title">{this.state.title}</h3>
                <Table
                    data={this.state.data}
                    header={this.state.header}
                    handleRowClick={this.handleRowClick}
                    handleHeaderClick={this.handleHeaderClick}
                    rowButtons={this.state.rowButtons}
                />
                <Table
                    className={"cart-summary"}
                    data={[{ "suma": this.state.summary, _id: "summary" }]}
                    header={[{ name: "suma", title: ml.getText("Kwota"), field: "suma", className: "right", compile: true }]}
                    buttons={tableButtons}
                />
            </div>
        )
    }
}

import ParamsHOC from "../useParamsHookHOC"
export default ParamsHOC(Cart);