import React, { Component } from "react"
import axios from "axios"
import { SERVER_HOST, ACCESS_LEVEL_NORMAL_USER } from "../../config/global_constants"
import OrderSummary from "./OrderSummary"
import MLTexts from "../../MLTexts"
const ml = MLTexts.getInstance();

class SpecificOrderSummary extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    componentDidMount() {
        axios.get(`${SERVER_HOST}/order/${this.props.match.params.id}`, {
            headers: {
                "authorization": sessionStorage.token
            }
        }).then(res => {
            this.setState({ order: res.data });

        }).catch(e => {
        });
    }

    render() {
        let buttons = [{ title: ml.getText("Zamknij"), id: "close", to: sessionStorage.accessLevel >= ACCESS_LEVEL_NORMAL_USER ? "/orders": "/home", className: "tableButton order-summary-buttons" }];

        if(this.state.order && this.state.order.status != "paid")
            buttons.push({ title: ml.getText("Przejdź do płatności"), id: "payment", to: `/order/payment/${this.props.match.params.id}`, className: "tableButton order-summary-buttons" });

        return (
            <div>
                {this.state.order ?
                    <OrderSummary data={this.state.order} buttons={buttons} />
                    : null}
            </div>
        )
    }
}
import ParamsHOC from "../useParamsHookHOC"
export default ParamsHOC(SpecificOrderSummary);