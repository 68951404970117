import React from "react"
import Form from "../../Form"
import axios from "axios"
import { SERVER_HOST } from "../../../config/global_constants"
import MD5 from "../../../tools/md5generator"
import UsersForm from "./UsersForm"
import MLTexts from "../../../MLTexts"
const ml = MLTexts.getInstance();

class UsersEdit extends UsersForm {
    constructor(props) {
        super(props)

        this.state.params.email['disabled'] = true;
        
        this.state.buttons = [
            {
                title: ml.getText("Zapisz"), id: "edit", to: "", className: "tableButton green", onClick: (e, data, button) => {
                    e.preventDefault();

                    Object.keys(this.state.fieldToUse).forEach((key) => {
                        if (this.state.fieldToUse[key].length > 0) {
                            this.state.fieldToUse[key].forEach((subKey) => {
                                if (data[subKey] != undefined) {
                                    data[key] == undefined ? data[key] = {} : null;
                                    data[key][subKey] = data[subKey];
                                    delete data[subKey];
                                }
                            })
                        }
                    })

                    data["password"] != undefined && data["password"] != "" ? data["password"] = MD5(data["password"]) : delete data["password"];

                    axios.patch(`${SERVER_HOST}/users/${this.props.match.params.id}`, data, {
                        headers: {
                            "authorization": sessionStorage.token
                        }
                    }).then(res => {

                        if (res.status != 200) {
                            if (res.data.message) {
                                console.log(res.data.message)
                            }
                        }
                    }).catch(e => {
                        console.log(e)
                    }).finally(() => {
                        this.form.current.setState({ redirect: true });
                    })

                }
            },
            {
                title: ml.getText("Anuluj"), id: "cancel", to: "", className: "tableButton red", onClick: (e) => {
                    e.preventDefault();
                    this.form.current.setState({ redirect: true });
                }
            }
        ];
    }

    componentDidMount() {
        super.componentDidMount();

        axios.get(`${SERVER_HOST}/users/${this.props.match.params.id}`, {
            headers: {
                "authorization": sessionStorage.token
            }
        }).then(res => {

            if (res.status != 200) {
                if (res.data.message) {
                    console.log(res.data.message)
                }
            } else {
                this.form.current.setState({ data: this.prepareUserData(res.data) })
            }
        }).catch(e => {
            console.log(e)
        })
    }

    render() {
        return (
            <div>
                <h3 className="title">{ml.getText("Edytuj użytkownika")}</h3>
                <Form ref={this.form} redirectTo={"/admin/users"} data={this.state.data} params={this.state.params} buttons={this.state.buttons} />
            </div>
        )
    }
}

// if want react-router-dom v6 receive route props in class
import ParamsHOC from "../../useParamsHookHOC"
export default ParamsHOC(UsersEdit);