import React from "react"
import axios from "axios"
import { SERVER_HOST } from "../../../config/global_constants"
import ProductAdd from "./ProductAdd"
import Form from "../../Form"
import MLTexts from "../../../MLTexts"
const ml = MLTexts.getInstance();

class ProductEdit extends ProductAdd {

    constructor(props) {
        super(props)

        this.form = React.createRef();

        this.state = {
            data: {},
            fieldToUse: {
                name: [],
                properties: ["brand", "model", "condition", "weight", "price", "tax"],
                category: [],
                description: [],
                title: [],
                quantity: [],      
                catalog: []
            },
            params: {
                name: { label: ml.getText("Nazwa"), onClick: (e) => { } },
                title: { label: ml.getText("Tytuł") },
                category: { label: ml.getText("Kategoria"), type: "select", values: [{ _id: "", title: "" }] },
                catalog: { label: ml.getText("Katalog"), type: "text" },
                description: { label: ml.getText("Opis"), type: "textarea", className: "add-product-form-field" },
                condition: {label: ml.getText("Stan")},
                brand: { label: ml.getText("Marka") },
                model: { label: ml.getText("Model") },
                weight: { label: ml.getText("Waga") + " (g)" },
                quantity: { label: ml.getText("Ilość") },
                price: { label: ml.getText("Cena") },
                tax: { label: ml.getText("VAT") },
                photos: { label: ml.getText("Dodaj zdjęcia"), type: "file" }
            },
            convertValue: {
                "category": (value) => {
                    let found = this.state['params']['category']['values'].filter(function (f) { return f._id == value });

                    return { _id: value, title: found.length >0 ? found[0].title : value }
                }
            },
            redirectToList: false
        }


        this.state.buttons = [
            {
                title: ml.getText("Zapisz"), id: "edit", to: "", className: "tableButton green", onClick: (e, data, button) => {
                    e.preventDefault();

                    Object.keys(this.state.fieldToUse).forEach((key) => {
                        if (this.state.fieldToUse[key].length > 0) {
                            this.state.fieldToUse[key].forEach((subKey) => {
                                if (data[subKey] != undefined) {
                                    data[key] == undefined ? data[key] = {} : null;
                                    data[key][subKey] = data[subKey];
                                    delete data[subKey];
                                }
                            })
                        }
                    })

                    delete data['photos'];

                    const addPhoto = (id, callback) => {
                        let formData = new FormData()
                        for (let i = 0; i < data.files.length; i++) {
                            formData.append("files", data.files[i])
                        }
                        axios.post(`${SERVER_HOST}/products/photo/${id}`, formData, {
                            headers: {
                                "authorization": sessionStorage.token,
                                "Content-type": "multipart/form-data"
                            }
                        }).then(res => {
                            if (res.status != 200) {
                                if (res.data.message) {
                                    alert(res.data.message)
                                }
                            } else {
                                callback();
                            }
                        }).catch(e => {
                            alert(e.response.data.message)
                        }).finally(() => {

                        })
                    }


                    const deletePhoto = (id, photos, callback) => {
                        axios.post(`${SERVER_HOST}/products/photo/delete/${id}`, photos, {
                            headers: {
                                "authorization": sessionStorage.token
                            }
                        }).then(res => {
                            if (res.status != 200) {
                                if (res.data.message) {
                                    alert(res.data.message)
                                }
                            } else {
                                callback();
                            }
                        }).catch(e => {
                            alert(e.response.data.message)
                        }).finally(() => {

                        })
                    }


                    axios.patch(`${SERVER_HOST}/products/${this.props.match.params.id}`, data, {
                        headers: {
                            "authorization": sessionStorage.token
                        }
                    }).then(res => {
                        if (res.status != 200) {
                            if (res.data.message) {
                                alert(res.data.message)
                            }
                        }
                        if (data.files != undefined && data.files.length > 0) {
                            addPhoto(res.data._id, () => {
                                if (this['photoToDelete'] != undefined && this['photoToDelete'].length > 0) {
                                    deletePhoto(res.data._id, this['photoToDelete'], () => {
                                        this.form.current.setState({ redirect: true });
                                    })
                                } else {
                                    this.form.current.setState({ redirect: true });
                                }
                            })

                        } else {
                            if (this['photoToDelete'] != undefined && this['photoToDelete'].length > 0) {
                                deletePhoto(res.data._id, this['photoToDelete'], () => {
                                    this.form.current.setState({ redirect: true });
                                })
                            } else {
                                this.form.current.setState({ redirect: true });
                            }
                        }

                    }).catch(e => {
                        alert(e.response.data.message)
                    }).finally(() => {

                    })

                }
            },
            {
                title: ml.getText("Anuluj"), id: "cancel", to: "", className: "tableButton red", onClick: (e) => {
                    e.preventDefault();
                    this.form.current.setState({ redirect: true });
                }
            }
        ];
    }


    componentDidMount() {
        super.componentDidMount();
        let classObject = this;


        axios.get(`${SERVER_HOST}/categories`).then(cat => {
            this.state['params']['category']['values'] = cat.data;
            this.setState(this.state)


            axios.get(`${SERVER_HOST}/products/${this.props.match.params.id}`, {
                headers: {
                    "authorization": sessionStorage.token
                }
            }).then(res => {

                if (res.status != 200) {
                    if (res.data.message) {
                        console.log(res.data.message)
                    }
                } else {
                    let additional = {};
                    if (res.data.photos != undefined && res.data.photos.length > 0) {
                        additional['content'] = "";
                        additional['content'] += `<label class="form-label">Usuń zdjęcia</label><div>`;
                        res.data.photos.forEach((photo) => {
                            additional['content'] += `<img src="${SERVER_HOST}/products/photo/${photo.filename}" />`
                        })

                        additional['content'] += `</div>`;
                        additional['className'] = "product-photo-form";
                        additional['onClick'] = (e) => {
                            let className = "selected";
                            classObject['photoToDelete'] == undefined ? classObject['photoToDelete'] = [] : null;

                            if (e.target.classList.contains(className)) {
                                e.target.classList.remove(className);
                                classObject['photoToDelete'] = classObject['photoToDelete'].filter(function (f) { return f !== e.target.src.replace(`${SERVER_HOST}/products/photo/`, "") })
                            }
                            else {
                                e.target.classList.add(className);
                                classObject['photoToDelete'].push(e.target.src.replace(`${SERVER_HOST}/products/photo/`, ""))
                            }
                        }
                    }
                    this.form.current.setState({
                        data: this.convertValues(this.prepareUserData(res.data)), additional: additional
                    })
                }
            }).catch(e => {
                console.log(e)
            })
        });
    }

    convertValues(element) {
        Object.keys(element).forEach((key) => {
            if (this.state.convertValue[key] != undefined) {
                element[key] = this.state.convertValue[key](element[key]);
            }
            return element;
        })
        return element;
    }

    render() {
        return (
            <div>
                <h3 className="title">Edytuj produkt</h3>
                <Form ref={this.form} redirectTo={"/admin/products"} data={this.state.data} params={this.state.params} buttons={this.state.buttons} />
            </div>
        )
    }
}

// if want react-router-dom v6 receive route props in class
import ParamsHOC from "../../useParamsHookHOC"
export default ParamsHOC(ProductEdit);