import List from "../AdminList"
import axios from "axios"
import { SERVER_HOST } from "../../../config/global_constants"
import MLTexts from "../../../MLTexts"
const ml = MLTexts.getInstance();

export default class ProductsList extends List {
    constructor() {
        super();
        this.state = {
            data: [{ "_id": "", "name": "", "title": "", "description": "" }],
            title: ml.getText("Produkty"),
            fieldToUse: {
                properties: ["price", "brand", "model", "condition"]
            },
            newFields: {
                infos: [{ name: "brand" }, { name: "model", separator: "<br/>" }]
            },
            sort: {},
            header: [
                { name: "image", title: "", field: "image", compile: true, className: "tableRowImage" },
                { name: "name", title: ml.getText("Nazwa"), field: "name" },
                { name: "title", title: ml.getText("Etykieta"), field: "title" },
                { name: "infos", title: "", field: "infos", compile: true },
                { name: "category", title: ml.getText("Kategoria"), field: "category" },
                { name: "condition", title: ml.getText("Stan"), field: "condition" },
                { name: "price", title: ml.getText("Cena"), field: "price" },
                { name: "active", title: ml.getText("Aktywny"), field: "active" },
                { name: "quantity", title: ml.getText("Ilość"), field: "quantity" }
            ],
            convertValue: {
                "condition": (value) => { return ml.getText(value) },
                "active": (value) => { return value ? ml.getText("Tak") : ml.getText("Nie") },
                "category": (value) => { return ml.getText(value) }

            },
            rowButtons: [
                { title: ml.getText("Edytuj"), id: "edit", to: "edit", className: "tableButton green" },
                { title: ml.getText("Dodaj podobny"), id: "add", to: "add", className: "tableButton orange" },
                { title: ml.getText("Usuń"), id: "delete", to: "delete", className: "tableButton red" }
            ],
            tableButtons: [
                { title: ml.getText("Dodaj"), id: "add", to: "add", className: "tableButton" }
            ],
        }
    }

    handleRowClick = (e, data, field) => {
        if (field == "active") {
            let newData = {};
            data['active'] == ml.getText("Tak") ? newData['active'] = false : newData['active'] = true;
            
            if (confirm(`${ml.getText("Na pewno zmienić status na")} ${newData['active'] ? ml.getText("Tak") : ml.getText("Nie")}?`)) {
                axios.patch(`${SERVER_HOST}/products/${data._id}`, newData, {
                    headers: {
                        "authorization": sessionStorage.token
                    }
                }).then(res => {
                    if (res.status != 200) {
                        if (res.data.message) {
                            alert(res.data.message)
                        }
                    }
                    this.componentDidMount();
                }).catch(e => {
                    alert(e.response.data.message)
                }).finally(() => {

                })
            }
        } else if (field == "image") {
            const openInNewTab = (url) => {
                const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
                if (newWindow) newWindow.opener = null
            }
            openInNewTab(`/product/${data._id}`);
        }
    }

    componentDidMount() {
        super.componentDidMount();
        axios.get(`${SERVER_HOST}/products?admin=true`).then(res => {
            let newData = [];
            axios.get(`${SERVER_HOST}/categories`).then(cat => {
                let categories = cat.data;
                res.data.data.forEach((d) => {
                    if (d["photos"] == undefined || d["photos"].length == 0) {
                        d["image"] = `<img src="${SERVER_HOST}/products/photo/default-product.gif" />`
                    } else {
                        d["image"] = `<img src="${SERVER_HOST}/products/photo/${d["photos"][0]['filename']}" />`;
                    }
                    let filtered = categories.filter(function (c) { return c._id == d['category'] })
                    filtered.length > 0 ? d['category'] = filtered[0].title : d['category'] = "";
                    newData.push(this.prepareUserData(d))
                })
                this._isMounted ?
                    this.setState({ data: this.convertValues(newData) })
                    : null
            });
        })
    }
}