import React from "react"
import Admin from "./Admin"
import Table from "../Table"

export default class AdminList extends Admin {
    constructor() {
        super();
        this.state = {
            sort: {}
        }
    }

    convertValues(values) {
        values.map(element => {
            element = this.prepareUserData(element);
            Object.keys(element).forEach((key) => {
                if (this.state.convertValue[key] != undefined) {
                    element[key] = this.state.convertValue[key](element[key], element);
                }
                return element;
            })
        });
        return values;
    }

    sort(member) {
        if (this.state.sort[member] == undefined) {
            let sortedData = this.state.data.sort((a, b) => a[member] < b[member] ? -1 : 1);
            this.state.sort[member] = "ASC";
            this.setState({ data: sortedData, sort: this.state.sort })
        } else {
            let sortedData = this.state.data.sort((a, b) => a[member] < b[member] ? 1 : -1);
            delete this.state.sort[member];
            this.setState({ data: sortedData, sort: this.state.sort })
        }
    }

    handleRowClick = (e, data, field) => {
    }

    handleHeaderClick = (e, col) => {
        this.sort(col.field);
    }

    render() {
        return (
            <div>
                {super.render()}
                <h3 className="title">{this.state.title}</h3>
                <Table
                    data={this.state.data}
                    header={this.state.header}
                    handleRowClick={this.handleRowClick}
                    handleHeaderClick={this.handleHeaderClick}
                    buttons={this.state.tableButtons}
                    rowButtons={this.state.rowButtons}
                />
            </div>
        )
    }
}