import React, { Component } from "react"
import { Navigate } from "react-router-dom"
import axios from "axios"
import { SERVER_HOST } from "../../../config/global_constants"

class UsersDelete extends Component {
    constructor(props) {
        super(props)

        this.state = {
            redirectToList: false
        }
    }

    componentDidMount() {
        axios.delete(`${SERVER_HOST}/users/delete/${this.props.match.params.id}`, {
            headers: {
                "authorization": sessionStorage.token
            }
        }).then(res => {
            if (res.status != 200) {
                if (res.data.message) {
                    console.log(res.data.message)
                }
            }
        }).catch(e => {
            console.log(e)
        }).finally(() => {
            this.setState({ redirectToList: true });
        })
    }

    render() {
        return (
            <div>
                {this.state.redirectToList ? <Navigate to="/admin/users" replace /> : null}
            </div>
        )
    }
}

// if want react-router-dom v6 receive route props in class
import ParamsHOC from "../../useParamsHookHOC"
export default ParamsHOC(UsersDelete);