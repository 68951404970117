import React, { Component } from "react"
import "../../css/About.css"

export default class About extends Component {
    constructor() {
        super();

        this.state = {

        }
    }

    render() {
        return (
            <div className="about-view-main">
                <h3>Informacje o sprzedającym</h3>

                <p>Zajmujemy się szeroko pojmowanymi <strong>usługami informatycznymi</strong>, w szczególności: tworzenie oprogramowania w zakresie
                 rozwiązań webowych, baz danych, ERP, automatyki, tworzenie i obsługa infrastruktury sieciowej, itp.</p>

                <p>Firma <strong>WACHI</strong> jest kontynuatorem działającej od <strong>2005</strong> roku firmy <strong>DARCOPOLO</strong> - zajmujemy się <strong>ploterami tnącymi, częściami i 
                    akcesoriami</strong> do nich oraz <strong>oprogramowaniem sterującym</strong> do tych urządzeń. W miarę możliwości, wraz z rozwojem technologii i rodzeniem 
                    się nowych potrzeb rynku staramy się poszerzać zakres działalności, zawsze dbając nie tylko o swój interes ekonomiczny, ale 
                    również satysfakcję naszych klientów.</p>
                <h4>Pozostałe informacje</h4>

                <p>Informacje na temat oferowanych przeze mnie noży i właściwego ich użytkowania znajdziesz na: <a target="_blank" href="http://darcopolo.pl/darcopolo.pl/baza_wiedzy.html">http://darcopolo.pl/darcopolo.pl/baza_wiedzy.html</a></p>
            </div>
        )
    }
}
