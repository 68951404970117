class MLTexts {

    constructor(lang) {
        this.data = [];
        this.getData();
        this.lang = lang;
    }

    static getInstance(lang) {
        //console.log("caller",  (new Error()).stack)
        if (!this.INSTANCE) {
            this.INSTANCE = new MLTexts(lang);
        }
        return this.INSTANCE;
    }

    getData() {
        this.data = [
            { plk: "O nas", eng: "About" },
            { plk: "Kontakt", eng: "Contact" },
            { plk: "Dostawa", eng: "Delivery " },
            { plk: "Koszyk", eng: "Cart" },
            { plk: "Konto", eng: "Account" },
            { plk: "Wszystkie", eng: "All" },
            { plk: "Kategorie", eng: "Categories" },
            { plk: "Profil", eng: "Profile" },
            { plk: "Zamówienia", eng: "Orders" },
            { plk: "Wyloguj", eng: "Log out" },
            { plk: "Zaloguj", eng: "Login" },
            { plk: "Plotery", eng: "Plotters" },
            { plk: "Akcesoria ploterowe", eng: "Plotter accessories" },
            { plk: "Części do ploterów", eng: "Parts for plotters" },
            { plk: "Drukarki", eng: "Printers" },
            { plk: "Akcesoria drukarek", eng: "Printer accessories" },
            { plk: "Części do drukarek", eng: "Printer parts" },
            { plk: "Urządzenia medyczne", eng: "Medical equipment" },
            { plk: "Tablet graficzne / Digitizery", eng: "Tablet Graphics / Digitizers" },
            { plk: "Czego szukasz?", eng: "What are you looking for?" },
            { plk: "Szukaj", eng: "Search" },
            { plk: "Nieprawidłowy email!", eng: "Invalid email!" },
            { plk: "Błąd połączenia z serwerem!", eng: "Server connection error!" },
            { plk: "Zalogowano pomyślnie!", eng: "Logged in successfully!" },
            { plk: "Błędny login lub hasło!", eng: "Incorrect login or password!" },
            { plk: "Zarejestruj", eng: "Register" },
            { plk: "Błędne potwierdzenie hasła!", eng: "Wrong password confirmation!" },
            { plk: "Hasło jest za krótkie!", eng: "Password is too short!" },
            { plk: "Hasło musi zawierać co najmniej jedną cyfrę (0-9).", eng: "Password must contain at least one digit (0-9)." },
            { plk: "Hasło musi zawierać co najmniej jedną małą literę.", eng: "Password must contain at least one lowercase character." },
            { plk: "Hasło musi zawierać co najmniej jedną wielką literę.", eng: "Password must contain at least one uppercase character." },
            { plk: "Hasło musi zawierać co najmniej jeden ze znaków £!#€$%^&* ", eng: "Password must contain at least one of the characters £!#€$%^&*" },
            { plk: "eMail", eng: "eMail" },
            { plk: "Hasło", eng: "Password" },
            { plk: "Potwierdź hasło", eng: "Confirm password" },
            { plk: "Zapisz", eng: "Save" },
            { plk: "Dane zaktualizowane", eng: "Data updated" },
            { plk: "Nowe hasło", eng: "New password" },
            { plk: "Imię", eng: "First name" },
            { plk: "Nazwisko", eng: "Surname" },
            { plk: "Firma", eng: "Company" },
            { plk: "Telefon", eng: "Phone" },
            { plk: "Adres", eng: "Address" },
            { plk: "Miasto", eng: "City" },
            { plk: "Kod pocztowy", eng: "Postcode" },
            { plk: "Kraj", eng: "Country" },
            { plk: "Adres dostawy", eng: "Delivery address" },
            { plk: "Nazwa firmy", eng: "Company name" },
            { plk: "NIP", eng: "Tax ID" },
            { plk: "Dane do faktury", eng: "Billing" },
            { plk: "Dane firmy", eng: "Company's data" },
            { plk: "Marka", eng: "Brand" },
            { plk: "Stan", eng: "Condition" },
            { plk: "Model", eng: "Model" },
            { plk: "Waga", eng: "Weight" },
            { plk: "Dodaj do koszyka", eng: "Add to cart" },
            { plk: "nowy", eng: "new" },
            { plk: "Parametry", eng: "Parameters" },
            { plk: "Polecane", eng: "Recommended" },
            { plk: "Numer katalogowy", eng: "Catalog number" },
            { plk: "Produkty", eng: "Products" },
            { plk: "Cena rosnąco", eng: "Ascending price" },
            { plk: "Cena malejąco", eng: "Descending price" },
            { plk: "Trafność", eng: "Relevance" },
            { plk: "Sortuj", eng: "Sort" },
            { plk: "Następna", eng: "Next" },
            { plk: "Poprzednia", eng: "Previous" },
            { plk: "Produkt", eng: "Product" },
            { plk: "Cena", eng: "Price" },
            { plk: "Łącznie", eng: "Total" },
            { plk: "Ilość", eng: "Quantity" },
            { plk: "Kwota", eng: "Amount" },
            { plk: "VAT", eng: "Tax" },
            { plk: "Usuń", eng: "Remove" },
            { plk: "Czy na pewno chcesz usunąć?", eng: "Are you sure you want to remove?" },
            { plk: "Realizuj zamówienie", eng: "Finalize the order" },
            { plk: "Adres eMail", eng: "E-mail adress" },
            { plk: "Stwórz konto", eng: "Create an account" },
            { plk: "Dalej", eng: "Next" },
            { plk: "Podaj imię!", eng: "Enter your first name!" },
            { plk: "Podaj nazwisko!", eng: "Enter your surname!" },
            { plk: "Wprowadź hasło!", eng: "Enter your password!" },
            { plk: "Użytkownik już istnieje!", eng: "User already exists!" },
            { plk: "Błąd serwera!", eng: "Server error!" },
            { plk: "Zaloguj się", eng: "Sign In" },
            { plk: "Zamów jako gość", eng: "Order as guest" },
            { plk: "Przejdź do płatności", eng: "Go to payment" },
            { plk: "Wstecz", eng: "Back" },
            { plk: "Kupujący", eng: "Buyer" },
            { plk: "Numer zamówienia", eng: "Order number" },
            { plk: "Data płatności", eng: "Payment date" },
            { plk: "Anulowano", eng: "Canceled" },
            { plk: "Opłacono", eng: "Paid" },
            { plk: "Zamówienie", eng: "Order" },
            { plk: "Zamknij", eng: "Close" },
            { plk: "Wybierz metodę płatności", eng: "Choose payment method" },
            { plk: "Do zapłaty", eng: "Total to be paid" },
            { plk: "Lista zamówień", eng: "List of orders" },
            { plk: "Identyfikator zamówienia", eng: "Order ID" },
            { plk: "Data zamówienia", eng: "Date of order" },
            { plk: "Produktów", eng: "Products" },
            { plk: "Kwota zamówienia", eng: "Order amount" },
            { plk: "Status zamówienia", eng: "Order status" },
            { plk: "nieopłacone", eng: "unpaid" },
            { plk: "opłacone", eng: "paid" },
            { plk: "anulowane", eng: "canceled" },
            { plk: "Wyświetl", eng: "View" },
            { plk: "Użytkownicy", eng: "Users" },
            { plk: "Nazwa", eng: "Name" },
            { plk: "Etykieta", eng: "Label" },
            { plk: "Kategoria", eng: "Category" },
            { plk: "Aktywny", eng: "Active" },
            { plk: "używany", eng: "used" },
            { plk: "Tak", eng: "Yes" },
            { plk: "Nie", eng: "No" },
            { plk: "Edytuj", eng: "Edit" },
            { plk: "Dodaj", eng: "Add" },
            { plk: "Dodaj podobny", eng: "Add similar" },
            { plk: "Na pewno zmienić status na", eng: "Are you sure you want to change the status to" },
            { plk: "Tytuł", eng: "Title" },
            { plk: "Katalog", eng: "Catalog" },
            { plk: "Opis", eng: "Description" },
            { plk: "Zdjęcia", eng: "Photos" },
            { plk: "Anuluj", eng: "Cancel" },
            { plk: "Dodaj produkt", eng: "Add product" },
            { plk: "Dodaj zdjęcia", eng: "Add photos" },
            { plk: "Czas rejestracji", eng: "Registration time" },
            { plk: "Ostatnie logowanie", eng: "Last login" },
            { plk: "Typ", eng: "Type" },
            { plk: "Zalogowany", eng: "Logged" },
            { plk: "Edytuj użytkownika", eng: "Edit user" },
            { plk: "Dodaj kategorię", eng: "Add Category" },
            { plk: "Wszelkie prawa zastrzeżone", eng: "All rights reserved" },
            { plk: "Kontynuuj zakupy", eng: "Continue shopping" },
            { plk: "Wentylatory", eng: "Fans" },
            { plk: "Programowanie", eng: "Programming" }
        ];
    }

    getText(text) {
        let lang = this.lang ? this.lang : localStorage.lang ? localStorage.lang : "plk";
        if (text != "") {
            let found = false;
            this.data.some((element) => {
                Object.keys(element).some((key) => {
                    found = element[key] == text;
                    if (found && element[lang]) {
                        text = element[lang];
                    }
                    return found;
                })
                return found;
            })
        }
        return text;
    }
}

export default MLTexts;