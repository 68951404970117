import React, { Component } from "react"
import axios from "axios"
import { SERVER_HOST, ACCESS_LEVEL_NORMAL_USER } from "../../config/global_constants"
import "../../css/Order.css"
import { Navigate } from "react-router-dom"
import Form from "./../Form"
import MD5 from "../../tools/md5generator"
import OrderSummary from "./OrderSummary"
import MLTexts from "../../MLTexts"
const ml = MLTexts.getInstance();

class Order extends Component {

    constructor() {
        super();
        this.state = {
            cart: [],
            title: ml.getText("Zamówienie"),
            step: sessionStorage.accessLevel >= ACCESS_LEVEL_NORMAL_USER ? 2 : 1,
            redirect: false,
            redirectTo: "/cart",
            createAccount: false,
            orderAsUser: false,
            data: {
                "firstName": "",
                "familyName": "",
                "eMail": "",
                "createAccount": "",
                "password": "",
                "confirmPassword": ""
            },
            params: {
                "firstName": { label: ml.getText("Imię") },
                "familyName": { label: ml.getText("Nazwisko") },
                "eMail": { label: ml.getText("Adres eMail") },
                "createAccount": { label: ml.getText("Stwórz konto"), type: "checkbox" }
            },
            orderData: {
                userData: {
                    "eMail": "",
                    "firstName": "",
                    "familyName": "",
                    "company": "",
                    "phone": ""
                },
                userParams: {
                    "eMail": { label: ml.getText("Adres eMail"), disabled: true },
                    "firstName": { label: ml.getText("Imię") },
                    "familyName": { label: ml.getText("Nazwisko") },
                    "company": { label: ml.getText("Nazwa firmy") },
                    "phone": { label: ml.getText("Telefon") }
                },
                deliveryAddressData: {
                    "address": "",
                    "zip": "",
                    "city": "",
                    "country": "",
                    "phone": ""
                },
                deliveryAddressParams: {
                    "address": { label: ml.getText("Adres") },
                    "zip": { label: ml.getText("Kod pocztowy") },
                    "city": { label: ml.getText("Miasto") },
                    "country": { label: ml.getText("Kraj") },
                    "phone": { label: ml.getText("Telefon") }
                },
                billingAddressData: {
                    "address": "",
                    "zip": "",
                    "city": "",
                    "country": "",
                    "taxID": "",
                    "company": ""
                },
                billingAddressParams: {
                    "address": { label: ml.getText("Adres") },
                    "zip": { label: ml.getText("Kod pocztowy") },
                    "city": { label: ml.getText("Miasto") },
                    "country": { label: ml.getText("Kraj") },
                    "taxID": { label: ml.getText("NIP") },
                    "company": { label: ml.getText("Firma") }
                }
            },
            order: {},
            buttons: [
                {
                    title: ml.getText("Dalej"), id: "next", to: "", className: "tableButton", onClick: (e, b, c) => {
                        if (this.state.step == 1) {
                            this.step1Button(e);
                        } else if (this.state.step == 2) {
                            this.step2Button(e);
                        }
                    }
                }
            ]
        }
        this.form = React.createRef();
        this.userForm = React.createRef();
        this.deliveryAddressForm = React.createRef();
        this.billingAddressForm = React.createRef();
    }

    step1Button(e) {
        e.preventDefault();
        let errorMsg = '';
        if (!this.state.orderAsUser) {
            this.form.current.state.data['firstName'] != "" ? null : errorMsg += `<li>${ml.getText("Podaj imię!")}</li>`;
            this.form.current.state.data['familyName'] != "" ? null : errorMsg += `<li>${ml.getText("Podaj nazwisko!")}</li>`
        }

        this.form.current.state.data['eMail'] == "" || !this.validateEmail(this.form.current.state.data['eMail']) ? errorMsg += `<li>${ml.getText("Nieprawidłowy email!")}</li>` : null;
        if (this.state.createAccount) {
            this.form.current.state.data['password'] == "" || !this.validatePassword(this.form.current.state.data['password']) ? errorMsg += this.generatePasswordErrorMsg(this.form.current.state.data['password']) : null;
            this.form.current.state.data['password'] == "" || this.form.current.state.data['password'] != this.form.current.state.data['confirmPassword'] ? errorMsg += `<li>${ml.getText("Wrong password confirmation!")}</li>` : null;
        } else if (this.state.orderAsUser) {
            this.form.current.state.data['password'] != "" ? null : errorMsg += `<li>${ml.getText("Wprowadź hasło!")}</li>`
        }

        if (this.state.createAccount && errorMsg == "") {
            axios.post(`${SERVER_HOST}/users/register/${this.form.current.state.data['eMail']}/${MD5(this.form.current.state.data['password'])}`,
                {
                    profile: {
                        firstName: this.form.current.state.data['firstName'],
                        familyName: this.form.current.state.data['familyName']
                    }
                })
                .then(res => {
                    if (res.data) {
                        sessionStorage.name = res.data.name;
                        sessionStorage.accessLevel = res.data.accessLevel;
                        sessionStorage.token = res.data.token;
                        this.setState({ step: 2 })
                    }
                    this.setState({ responseCode: res.status });
                }).catch(e => {
                    this.form.current.setState({ additional: { content: `<span>${ml.getText("Użytkownik już istnieje!")}</span>`, className: "error" } });
                })
        } else if (this.state.orderAsUser) {
            axios.post(`${SERVER_HOST}/users/login/${this.form.current.state.data['eMail']}/${MD5(this.form.current.state.data['password'])}`)
                .then(res => {
                    if (res.data) {
                        sessionStorage.name = res.data.name;
                        sessionStorage.accessLevel = res.data.accessLevel;
                        sessionStorage.token = res.data.token;
                        this.setState({ step: 2 })
                    } else {
                        this.form.current.setState({ additional: { content: `<span>${ml.getText("Błąd serwera!")}</span>`, className: "error" } });
                    }
                }).catch(e => {
                    this.form.current.setState({ additional: { content: `<span>${ml.getText("Błędny login lub hasło!")}</span>`, className: "error" } });
                })
        } else if (errorMsg == "") {
            this.state.orderData.userData['firstName'] = this.form.current.state.data['firstName'];
            this.state.orderData.userData['familyName'] = this.form.current.state.data['familyName'];
            this.state.orderData.userData['eMail'] = this.form.current.state.data['eMail'];
            this.setState({ step: 2, orderData: this.state.orderData })
        } else {
            this.form.current.setState({ additional: { content: errorMsg, className: "error" } });
        }
    }

    step2Button(e) {
        this.state.orderData["userData"] = this.userForm.current.state.data;
        this.state.orderData["deliveryAddressData"] = this.deliveryAddressForm.current.state.data;
        this.state.orderData["billingAddressData"] = this.billingAddressForm.current.state.data;
        let order = {
            userData: this.state.orderData["userData"],
            deliveryAddressData: this.state.orderData["deliveryAddressData"],
            billingAddressData: this.state.orderData["billingAddressData"],
            products: [],
            cart: this.state.cart
        }
        this.state.step = 3;
        this.state.cart.products.forEach((pos) => {
            order.products.push({
                "title": pos.product.title,
                "price": pos.product.properties.price,
                "tax": pos.product.properties.tax,
                "quantity": pos.quantity,
                "_id": pos.product._id,
                "image": pos.product.photos[0] ? `<img src="${SERVER_HOST}/products/photo/${pos.product.photos[0].filename}"/>` : `<img src="${SERVER_HOST}/products/photo/default-product.gif"/>`,
                "sum": (pos.quantity * pos.product.properties.price).toFixed(2)
            })
        })
        if (this.state.createAccount) {
            let data = { profile: this.state.orderData["userData"] }
            if (data.profile["company"]) {
                data.profile["companyName"] = data.profile["company"];
                delete data.profile["company"];
            }
            data.profile["deliveryAddress"] = this.state.orderData["deliveryAddressData"];
            data.profile["billingAddress"] = this.state.orderData["billingAddressData"];

            axios.patch(`${SERVER_HOST}/users`, data, {
                headers: {
                    "authorization": sessionStorage.token
                }
            }).then(res => {
            }).catch(e => {
            })
        }
        this.state.order = order;
        this.setState(this.state);
    }

    getUserData(callback) {
        axios.get(`${SERVER_HOST}/users/profile`, {
            headers: {
                "authorization": sessionStorage.token
            }
        }).then(res => {
            res.data['email'] ? this.state.orderData.userData['eMail'] = res.data['email'] : null;
            res.data['profile']["firstName"] ? this.state.orderData.userData['firstName'] = res.data['profile']["firstName"] : null;
            res.data['profile']["familyName"] ? this.state.orderData.userData['familyName'] = res.data['profile']["familyName"] : null;
            res.data['profile']["companyName"] ? this.state.orderData.userData['company'] = res.data['profile']["companyName"] : null;
            res.data['profile']["billingAddress"] ? this.state.orderData.billingAddressData = res.data['profile']["billingAddress"] : null;
            res.data['profile']["deliveryAddress"] ? this.state.orderData.deliveryAddressData = res.data['profile']["deliveryAddress"] : null;
            res.data['profile']["phone"] ? this.state.orderData.userData['phone'] = res.data['profile']["phone"] : null;

            this.userForm.current.setState({ data: this.state.orderData.userData });
            this.billingAddressForm.current.setState({ data: this.state.orderData.billingAddressData });
            this.deliveryAddressForm.current.setState({ data: this.state.orderData.deliveryAddressData });

            callback(this.state.orderData);
        }).catch(e => {

        });;

    }

    componentDidMount() {
        let url = `${SERVER_HOST}/cart`;
        localStorage.cart != undefined ? url += `/${localStorage.cart}` : null;
        axios.get(url).then(res => {
            res.data["_id"] != undefined ? localStorage.cart = res.data["_id"] : null;
            if (this.state.step == 2 && sessionStorage.accessLevel >= ACCESS_LEVEL_NORMAL_USER) {
                this.getUserData((data) => {
                    this.setState({ cart: res.data, redirect: res.data.products.length == 0, orderData: data })
                })
            } else {
                this.setState({ cart: res.data, redirect: res.data.products.length == 0 })
            }

        }).catch(e => {
            this.setState({ redirect: true })
        })
    }

    generatePasswordErrorMsg(password) {
        let passwordErrorList = []

        if (password.length < 10) {
            passwordErrorList.push(ml.getText("Password is too short!"))
        }
        if (!password.match(/[0-9]/)) {
            passwordErrorList.push(ml.getText("Password must contain at least one digit (0-9)."))
        }
        if (!password.match(/[a-z]/)) {
            passwordErrorList.push(ml.getText("Password must contain at least one lowercase character."))
        }
        if (!password.match(/[A-Z]/)) {
            passwordErrorList.push(ml.getText("Password must contain at least one uppercase character."))
        }
        if (!password.match(/[£!#€$%^&*]/)) {
            passwordErrorList.push(ml.getText("Password must contain at least one of the characters £!#€$%^&*"))
        }
        let ret = ``;
        passwordErrorList = passwordErrorList.forEach(e => ret += `<li>${e}</li>`);
        return ret;
    }

    handleChange(e) {
        if (e.target.name == "createAccount") {
            this.setState({ createAccount: e.target.checked })
        }

        if (e.target.name == "eMail" || e.target.name == "password" || e.target.name == "confirmPassword" || e.target.name == "createAccount") {
            let errorMsg = '';
            this.form.current.state.data['eMail'] == "" || this.validateEmail(this.form.current.state.data['eMail']) ? null : errorMsg += `<li>${ml.getText("Nieprawidłowy email!")}</li>`;

            if (this.state.createAccount && (e.target.name != "createAccount" || e.target.checked)) {
                this.form.current.state.data['password'] == "" || this.validatePassword(this.form.current.state.data['password']) ? null : errorMsg += this.generatePasswordErrorMsg(this.form.current.state.data['password']);
                this.form.current.state.data['password'] == "" || this.form.current.state.data['password'] == this.form.current.state.data['confirmPassword'] ? null : errorMsg += `<li>${ml.getText("Wrong password confirmation!")}</li>`;
            }
            errorMsg != '' ?
                this.form.current.setState({ additional: { content: `<span>${errorMsg}</span>`, className: "error" } })
                : this.form.current.setState({ additional: null })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.step == 1 && this.state.step == 2 && sessionStorage.accessLevel >= ACCESS_LEVEL_NORMAL_USER) {
            this.getUserData((data) => {
                this.setState({ orderData: data })
            })
        }
    }

    validateEmail(email) {
        const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(String(email).toLowerCase())
    }

    validatePassword(password) {
        const pattern = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[£!#€$%^&*]).{10,}$/
        return pattern.test(String(password))
    }

    crateOrder(e) {
        e.preventDefault();
        axios.post(`${SERVER_HOST}/order`, this.state.order).then(res => {
            this.props.navigate(`/order/payment/${res.data._id}`)
        }).catch(e => {
            console.log("error", e)
        })


    }

    render() {
        let params = Object.assign({}, this.state.params);
        if (this.state.step == 1) {
            if (this.state.createAccount || this.state.orderAsUser) {
                params["password"] = { label: ml.getText("Hasło"), type: "password" };
            }
            if (this.state.createAccount)
                params["confirmPassword"] = { label: ml.getText("Potwierdź hasło"), type: "password" };
            if (this.state.orderAsUser) {
                delete params["firstName"];
                delete params["familyName"];
                delete params["createAccount"];
            }
        }
        return (
            <div>
                {this.state.redirect ? <Navigate to={this.state.redirectTo} replace /> : null}

                {this.state.step == 1 ?
                    <div>
                        <h3 className="title">{this.state.title}</h3>
                        <div className="order-title"><div><div className={!this.state.orderAsUser ? "order-title-bold" : null} onClick={() => { this.setState({ orderAsUser: false }) }}>{ml.getText("Zamów jako gość")}</div> | <div className={this.state.orderAsUser ? "order-title-bold" : null} onClick={() => { this.setState({ orderAsUser: true, createAccount: false }) }}>{ml.getText("Zaloguj się")}</div></div></div>
                        <Form ref={this.form} redirectTo={"/order"} data={this.state.data} params={params} buttons={this.state.buttons} handleChange={this.handleChange.bind(this)} />
                    </div>
                    : this.state.step == 2 ?
                        <div>
                            <h3 className="title">{this.state.title}</h3>
                            <Form ref={this.userForm} data={this.state.orderData.userData} params={this.state.orderData.userParams} />
                            <div className="order-title"><div>{ml.getText("Adres dostawy")}</div></div>
                            <Form ref={this.deliveryAddressForm} data={this.state.orderData.deliveryAddressData} params={this.state.orderData.deliveryAddressParams} />
                            <div className="order-title"><div>{ml.getText("Dane do faktury")}</div></div>
                            <Form ref={this.billingAddressForm} buttons={this.state.buttons} data={this.state.orderData.billingAddressData} params={this.state.orderData.billingAddressParams} />
                        </div>
                        :
                        this.state.step == 3 ?
                            <div>
                                <OrderSummary data={this.state.order} buttons={[
                                    { title: ml.getText("Wstecz"), id: "back", to: "", className: "tableButton order-summary-buttons", onClick: (e) => { e.preventDefault(); this.setState({ step: 2 }) } },
                                    {
                                        title: ml.getText("Przejdź do płatności"), id: "payment", to: "/order/payment", className: "tableButton order-summary-buttons", onClick: (e) => {
                                            this.crateOrder(e);
                                        }
                                    }
                                ]} />
                            </div>
                            : null
                }
            </div>
        )
    }
}

import ParamsHOC from "../useParamsHookHOC"
export default ParamsHOC(Order);