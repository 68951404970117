import React, { Component } from "react"
import { Link } from "react-router-dom"

import "../../css/ProductHome.css"

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MLTexts from "../../MLTexts"
const ml = MLTexts.getInstance();
export default class ProductHomeSinglePorduct extends Component {

    constructor(props) {
        super(props)
        this.state = {
            redirect: false,
            data: {}
        }
    }

    render() {
        return (
            <div className="product-card" key={this.props.data._id}>
                <Link to={"/product/" + this.props.data._id}>
                    <div className="product-card-center"><img src={this.props.data.image} /></div>
                    <div><p>{this.props.data.title}</p></div>
                </Link>
                {this.props.data.properties ?
                    <div>
                        <div className="product-card-condition" ><div>{this.props.data.properties.condition !== undefined ? ml.getText("Stan")+":" : null}</div>{this.props.data.properties.condition !== undefined ? ml.getText(this.props.data.properties.condition) : ""}</div>
                        <div className="product-card-price" >{this.props.data.properties.price !== undefined ? (Math.round(this.props.data.properties.price * 100) / 100).toFixed(2) + " zł" : ""}</div>
                        <div className="product-card-price-netto" >{this.props.data.properties.price !== undefined ? (Math.round(this.props.data.properties.price * 100) / (100 + (this.props.data.properties.tax != undefined ? this.props.data.properties.tax : 0))).toFixed(2) + " zł" : ""}<div>{this.props.data.properties.price !== undefined ? "netto" : ""}</div></div>
                    </div>
                    : null}
            </div>
        )
    }

}

