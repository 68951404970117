import React, { Component } from "react"
import { Link } from "react-router-dom"
import axios from "axios"
import { SERVER_HOST } from "../../config/global_constants"
import "../../css/Product.css"
import $ from 'jquery';
import 'slick-carousel';
import parse from 'html-react-parser'
import ModalPhoto from "../ModalPhoto"
import MLTexts from "../../MLTexts"
const ml = MLTexts.getInstance();

class Product extends Component {
    constructor() {
        super();
        this.modalPhoto = React.createRef();

        this.state = {
            notFound: false,
            data: {},
            propertiesMap: {
                brand: ml.getText("Marka"),
                condition: ml.getText("Stan"),
                model: ml.getText("Model"),
                weight: ml.getText("Waga")
            },
            convertValue: {
                "condition": (value) => { return ml.getText(value) }
            },
            buttons: [
                { title: ml.getText("Dodaj do koszyka"), id: "addToCart", to: "/cart/add", className: "tableButton" },
                /*{ title: "Dostawa", id: "addToCa22rt", to: "addToCar2t", className: "tableButton" },
                { title: "Pytanie", id: "addToCa222rt", to: "addToCart22", className: "tableButton" }*/
            ],
            showModalPhoto: false
        }
    }


    toggleModalPhoto(e) {
        if (e != undefined && e.target != undefined)
            this.modalPhoto = e.target.src;
        this.setState({ showModalPhoto: !this.state.showModalPhoto })
    }

    initSliderPhotoView() {

        $('.slider-for').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true,
            asNavFor: '.slider-nav'
        });
        $('.slider-nav').slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            asNavFor: '.slider-for',
            dots: true,
            focusOnSelect: true
        });

        $('a[data-slide]').click(function (e) {
            e.preventDefault();
            var slideno = $(this).data('slide');
            $('.slider-nav').slick('slickGoTo', slideno - 1);
        });
    }

    convertValues(element) {
        Object.keys(element).forEach((key) => {
            if (this.state.convertValue[key] != undefined) {
                element[key] = this.state.convertValue[key](element[key]);
            }
            return element;
        })
        return element;
    }

    componentDidMount() {
        axios.get(`${SERVER_HOST}/products/${this.props.match.params.id}`, {
            headers: {
                "authorization": sessionStorage.token
            }
        }).then(res => {

            if (res.status != 200) {
                if (res.data.message) {
                    console.log(res.data.message)
                }
                this.setState({ notFound: true })
            } else {
                res.data.properties == undefined ? res.data.properties = {} : null;
                res.data.properties = this.convertValues(res.data.properties);
                this.setState({ data: res.data })
                this.initSliderPhotoView();
            }
        }).catch(e => {
            this.setState({ notFound: true })
        })

    }

    render() {
        return (
            <div>
                <div className="product-view-root">
                    {this.state.showModalPhoto ? <ModalPhoto ref={this.modalPhoto} src={this.modalPhoto || ""} closeModal={this.toggleModalPhoto.bind(this)} /> : null}

                    <div className="product-view-photo">
                        <div className="product-view-photos-container">
                            <div className="main">
                                <div className="slider slider-for">
                                    {this.state.data.photos != undefined ?
                                        this.state.data.photos.map(photo =>
                                            <div key={photo._id}><img onClick={this.toggleModalPhoto.bind(this)} src={`${SERVER_HOST}/products/photo/` + photo.filename} />
                                            </div>
                                        ) : null}
                                </div>
                                <div className="slider slider-nav">
                                    {this.state.data.photos != undefined ?
                                        this.state.data.photos.map(photo =>
                                            <div key={photo._id}><img src={`${SERVER_HOST}/products/photo/` + photo.filename} /></div>
                                        ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="product-view-properties">
                        <h3 className="product-view-properties-title">{this.state.data.title}</h3>
                        <h5>{ml.getText("Parametry")}</h5>
                        {this.state.data.properties != undefined ? Object.keys(this.state.data.properties).map(property =>
                            this.state.propertiesMap[property] != undefined && this.state.data.properties[property] != "" && this.state.data.properties[property] ?
                                <div key={property}>{this.state.propertiesMap[property]} : {this.state.data.properties[property]}</div>
                                : null
                        ) : null}
                        <div>
                        </div>
                        {this.state.data.properties ?
                            <div>
                                <div className="product-card-price" >{this.state.data.properties.price !== undefined ? (Math.round(this.state.data.properties.price * 100) / 100).toFixed(2) + " zł" : ""}</div>
                                <div className="product-card-price-netto" >{this.state.data.properties.price !== undefined ? (Math.round(this.state.data.properties.price * 100) / (100 + (this.state.data.properties.tax != undefined ? this.state.data.properties.tax : 0))).toFixed(2) + " zł" : ""}<div>{this.state.data.properties.price !== undefined ? " netto" : ""}</div></div>
                            </div>
                            : ""}
                        <div>
                            {this.state.buttons != undefined ?
                                this.state.buttons.map(button =>
                                    <Link key={button.id} className={button.className} onClick={button.onClick == undefined ? null : ((e) => button.onClick(e, button))} to={button.to + "/" + this.props.match.params.id}>{button.title}</Link>
                                )
                                : null}

                        </div>
                    </div>
                    <div className="product-view-main">
                        <div className="product-description-container">
                            {parse(this.state.data.description || "")}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

// if want react-router-dom v6 receive route props in class
import ParamsHOC from "../useParamsHookHOC"
export default ParamsHOC(Product);