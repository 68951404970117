import List from "../AdminList"
import axios from "axios"
import { SERVER_HOST, ACCESS_LEVEL_ADMIN } from "../../../config/global_constants"
import { reduce } from "lodash";
import MLTexts from "../../../MLTexts"
const ml = MLTexts.getInstance();

export default class AdminUsers extends List {

    constructor() {
        super();
        this.state = {
            data: [{ "_id": "", "name": "", "email": "", "accessLevel": 0, "registrationTime": "", "lastLogin": "" }],
            title: ml.getText("Użytkownicy"),
            fieldToUse: {
                profile: ["firstName", "familyName", "companyName"]
            },
            header: [
                { name: "email", title: ml.getText("eMail"), field: "email" },
                { name: "firstName", title: ml.getText("Imię"), field: "firstName" },
                { name: "familyName", title: ml.getText("Nazwisko"), field: "familyName" },
                { name: "companyName", title: ml.getText("Firma"), field: "companyName" },
                { name: "registrationTime", title: ml.getText("Czas rejestracji"), field: "registrationTime" },
                { name: "lastLogin", title: ml.getText("Ostatnie logowanie"), field: "lastLogin" },
                { name: "isLogged", title: ml.getText("Zalogowany"), field: "isLogged" },
                { name: "accessLevel", title: ml.getText("Typ"), field: "accessLevel" }
            ],
            sort: {},
            convertValue: {
                "registrationTime": (value) => { return new Date(value).toLocaleString() },
                "lastLogin": (value) => { return new Date(value).toLocaleString() },
                "accessLevel": (value) => { return value == ACCESS_LEVEL_ADMIN ? "Administrator" : "Uzytkownik" },
                "isLogged": (value) => { return value ? ml.getText("Tak") : ml.getText("Nie") },
            },
            rowButtons: [
                { title: ml.getText("Edytuj"), id: "edit", to: "edit", className: "tableButton green" },
                {
                    title: ml.getText("Usuń"), id: "delete", to: "delete", className: "tableButton red", onClick: (e) => {
                        confirm(ml.getText("Czy na pewno chcesz usunąć?")) ? null : e.preventDefault();
                    }
                }
            ],
            tableButtons: [
                { title: ml.getText("Dodaj"), id: "add", to: "add", className: "tableButton" }
            ],
        }
    }

    prepareUserData(data) {
        let fieldToUse = Object.keys(data).filter((field) => {
            if (this.state.fieldToUse[field] != undefined) {
                return true;
            }
            return false;
        })

        fieldToUse.forEach((field) => {
            if (this.state.fieldToUse[field].length > 0) {
                this.state.fieldToUse[field].forEach((subField) => {
                    if (data[field][subField] != undefined) {
                        data[subField] = data[field][subField];
                    }
                })
            } else {
                data[field] = data[field];
            }

        })
        return data;
    }

    componentDidMount() {
        super.componentDidMount();
            axios.get(`${SERVER_HOST}/users`, {
                headers: {
                    "authorization": sessionStorage.token
                }
            }).then(res => {                
                this._isMounted ? //&& res != undefined && res.data != undefined ?
                this.setState({ data: this.convertValues(res.data) })
                : null
            }).catch(e => {
            });
    }

}