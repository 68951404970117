import React, { Component } from "react"
import { Link } from "react-router-dom"
import "../css/LeftMenu.css"

export default class LeftMenu extends Component {

    static defaultProps = 
    {
        title:"Menu",
        data:[]
    }    

    render() {
        return (
            <div id="left-menu">
                <h3 className="title">{this.props.title}</h3>
                <nav>
                    <ul>
                        {this.props.data.map((pos) => <li key={pos.name}><Link to={pos.path}>{pos.title}</Link></li>)}
                    </ul>
                </nav>
            </div>
        )
    }
}

