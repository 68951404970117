import React, {Component} from "react"
import axios from "axios"
import { SERVER_HOST } from "../config/global_constants"
import ProductHomeContainer from "./Product/ProductHomeContainer"

export default class Home extends Component 
{
    constructor(){
        super();
        this.lastProductsCount = 8;
        this.state = {
            data: []
        }    
    }

    componentDidMount() {
        axios.get(`${SERVER_HOST}/products?last=${this.lastProductsCount}`).then(res => {
            let newData = [];
            res.data.data.forEach((d) => {

                if (d["photos"] == undefined || d["photos"].length == 0) {
                    d["image"] = `${SERVER_HOST}/products/photo/default-product.gif`
                } else {
                    d["image"] = `${SERVER_HOST}/products/photo/${d["photos"][0]['filename']}`;
                }

                newData.push(d)
            })
            this.setState({ data: newData });
        }).catch(e => {
        });
    }

    render() 
    {
        return (
            <div>
                <ProductHomeContainer data={this.state.data}/>
            </div>
        )
    }
}