import React from "react"
import Form from "../../Form"
import Admin from "../Admin"
import MLTexts from "../../../MLTexts"
const ml = MLTexts.getInstance();

export default class CategoriesForm extends Admin {
    constructor(props) {
        super(props)

        this.state = {
            data: {},
            params: {
                name: { label: ml.getText("Nazwa"), onClick: (e) => { } },
                title: { label: ml.getText("Etykieta") },
                description: { label: ml.getText("Opis") }
            },
            redirectToList: false
        }

        this.form = React.createRef();
    }

    componentDidMount() {
        super.componentDidMount();
    }

    render() {        
        return (
            <div>
                <Form ref={this.form} redirectTo={"/admin/categories"} data={this.state.data} params={this.state.params} buttons={this.state.buttons}/>
            </div>
        )
    }
}