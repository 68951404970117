import React, { Component } from "react"
import Form from "react-bootstrap/Form"
import { Link, Navigate } from "react-router-dom"
import "../css/Form.css"
import parse from 'html-react-parser'

function formatHTML(html) {
    var indent = '\n';
    var tab = '\t';
    var i = 0;
    var pre = [];
    if (html != undefined) {
        html = html
            .replace(new RegExp('<pre>((.|\\t|\\n|\\r)+)?</pre>'), function (x) {
                pre.push({ indent: '', tag: x });
                return '<--TEMPPRE' + i++ + '/-->'
            })
            .replace(new RegExp('<[^<>]+>[^<]?', 'g'), function (x) {
                var ret;
                var tag = /<\/?([^\s/>]+)/.exec(x)[1];
                var p = new RegExp('<--TEMPPRE(\\d+)/-->').exec(x);

                if (p)
                    pre[p[1]].indent = indent;

                if (['area', 'base', 'br', 'col', 'command', 'embed', 'hr', 'img', 'input', 'keygen', 'link', 'menuitem', 'meta', 'param', 'source', 'track', 'wbr'].indexOf(tag) >= 0) // self closing tag
                    ret = indent + x;
                else {
                    if (x.indexOf('</') < 0) { //open tag
                        if (x.charAt(x.length - 1) !== '>')
                            ret = indent + x.substr(0, x.length - 1) + indent + tab + x.substr(x.length - 1, x.length);
                        else
                            ret = indent + x;
                        !p && (indent += tab);
                    }
                    else {//close tag
                        indent = indent.substr(0, indent.length - 1);
                        if (x.charAt(x.length - 1) !== '>')
                            ret = indent + x.substr(0, x.length - 1) + indent + x.substr(x.length - 1, x.length);
                        else
                            ret = indent + x;
                    }
                }
                return ret;
            });

        for (i = pre.length; i--;) {
            html = html.replace('<--TEMPPRE' + i + '/-->', pre[i].tag.replace('<pre>', '<pre>\n').replace('</pre>', pre[i].indent + '</pre>'));
        }

        return html.charAt(0) === '\n' ? html.substr(1, html.length - 1) : html;
    }
}

function unformatHTML(html) {
    var i = 0;
    var pre = [];
    if (html != undefined) {
        html = html.replace(new RegExp('<pre>((.|\\t|\\n|\\r)+)?</pre>'), function (x) {
            pre.push(x);
            return '<--TEMPPRE' + i++ + '/-->'
        }).replace(/\n/g, '').replace(/\t/g, '');

        for (i = pre.length; i--;) {
            html = html.replace('<--TEMPPRE' + i + '/-->', pre[i]);
        }

        html = html.replace(new RegExp('<pre>\\n'), '<pre>').replace(new RegExp('\\n\\t*</pre>'), '</pre>');
    }
    return html;
}

export default class CustomForm extends Component {

    static defaultProps =
        {
            redirectTo: "/"
        }

    constructor(props) {
        super(props)
        this.state = { redirect: false, data: {}, errorMsg: "siema" }
        Object.keys(props.data).forEach(key => {
            this.state.data[key] = props.data[key] != undefined ? props.data[key] : "";
        })
        this.state.additional = props.additional;
    }

    handleChange = (e) => {
        if (e.target.type == "checkbox") {
            this.state.data[e.target.name] = e.target.checked;

        } else if (e.target.type == "textarea") {
            this.state.data[e.target.name] = unformatHTML(e.target.value);
        }
        else {
            this.state.data[e.target.name] = e.target.value;
        }
        if (e.target.files != undefined && e.target.files.length > 0) {
            this.state.data["files"] = e.target.files;
        }
        this.setState({ data: this.state.data })
        this.props.handleChange ? this.props.handleChange(e) : null;
    }

    render() {
        return (
            <div className="form-container">
                {this.state.redirect ? <Navigate to={this.props.redirectTo} replace /> : null}
                <Form>
                    {Object.keys(this.props.params).map(key =>
                        <Form.Group key={key} className={this.props.params[key]['type'] == "checkbox" ? "form-label-checkbox" : null}>
                            <Form.Label>{this.props.params[key] != undefined && this.props.params[key]['label'] != undefined ? this.props.params[key]['label'] : key}</Form.Label>
                            {this.props.params[key]['type'] != "select" ?
                                <div>
                                    {this.props.params[key] != undefined && this.props.params[key]['type'] == "textarea" ?
                                        <div>
                                            <textarea
                                                type={this.props.params[key] != undefined && this.props.params[key]['type'] != undefined ? this.props.params[key]['type'] : "text"}
                                                multiple
                                                name={key}
                                                value={this.state.data[key] ? formatHTML(this.state.data[key]) : '' || ''}
                                                onChange={this.handleChange}
                                                onClick={this.props.params[key] != undefined && this.props.params[key]['onClick'] != undefined ? this.props.params[key]['onClick'] : null}
                                                disabled={this.props.params[key] != undefined && this.props.params[key]['disabled'] != undefined ? this.props.params[key]['disabled'] : false}
                                                className={this.props.params[key] != undefined && this.props.params[key]['className'] != undefined ? this.props.params[key]['className'] + " form-control" : "form-control"}
                                            />
                                        </div>
                                        :
                                        <div>
                                            <Form.Control
                                                type={this.props.params[key] != undefined && this.props.params[key]['type'] != undefined ? this.props.params[key]['type'] : "text"}
                                                multiple
                                                name={key}
                                                value={this.state.data[key] || ''}
                                                onChange={this.handleChange}
                                                onClick={this.props.params[key] != undefined && this.props.params[key]['onClick'] != undefined ? this.props.params[key]['onClick'] : null}
                                                disabled={this.props.params[key] != undefined && this.props.params[key]['disabled'] != undefined ? this.props.params[key]['disabled'] : false}
                                                className={this.props.params[key] != undefined && this.props.params[key]['className'] != undefined ? this.props.params[key]['className'] : this.props.params[key]['type'] == "checkbox" ? "form-content-checkbox" : null}
                                            />
                                        </div>
                                    }
                                </div>
                                :
                                <select className="form-control" name={key} id={key} value={this.state.data[key] != undefined ? this.state.data[key].title || this.state.data[key]._id : undefined}>
                                    {this.props.params[key]["values"] != undefined ? this.props.params[key]["values"].map(value =>
                                        <option key={value._id} onClick={() => { this.handleChange({ target: { value: value, name: key } }) }}>{value.title || value._id}</option>
                                    ) : null}
                                </select>
                            }

                        </Form.Group>
                    )}
                    {this.state.additional != undefined ?
                        <div
                            className={this.state.additional['className'] != undefined ? this.state.additional['className'] : null}
                            onClick={this.state.additional['onClick'] != undefined && this.state.additional['onClick'] != undefined ? this.state.additional['onClick'] : null}
                        >
                            {this.state.additional.content != undefined ? parse(this.state.additional.content) : null}
                        </div>
                        : null}

                    {this.props.buttons != undefined ?
                        <div className="form-buttons">
                            {this.props.buttons.map(button =>
                                <Link key={button.id} className={button.className} onClick={button.onClick == undefined ? null : ((e) => button.onClick(e, this.state.data, button))} to={button.to}>{button.title}</Link>
                            )}
                        </div>
                        : null}
                </Form>
            </div>
        )
    }
}