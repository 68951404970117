import React from "react"
import { SERVER_HOST } from "../../config/global_constants"
import axios from "axios"
import List from "../Admin/AdminList"
import Table from "../Table"
import MLTexts from "../../MLTexts"
const ml = MLTexts.getInstance();

class OrderList extends List {
    constructor() {
        super();
        this.state = {
            data: [],
            title: ml.getText("Lista zamówień"),
            header: [
                { name: "_id", title: ml.getText("Identyfikator zamówienia"), field: "_id" },
                { name: "createTime", title: ml.getText("Data zamówienia"), field: "createTime" },
                { name: "productsCount", title: ml.getText("Produktów"), field: "productsCount" },
                { name: "quantityCount", title: ml.getText("Ilość"), field: "quantityCount" },
                { name: "sum", title: ml.getText("Kwota zamówienia"), field: "sum" },
                { name: "status", title: ml.getText("Status zamówienia"), field: "status" }
            ],
            newFields: {
                sum: [],
                productsCount: [],
                quantityCount: []
            },
            convertValue: {
                "createTime": (value) => { return new Date(value).toLocaleString() },
                "productsCount": (value, obj) => { return obj.products.length },
                "status": (value) => { return ml.getText(value) }, 
                "sum": (value, obj) => { 
                    let sum = 0;
                    obj.products.forEach(element => {
                        sum += element.price * element.quantity;
                    });                    
                    return `${sum} zł`;
                },
                "quantityCount": (value, obj) => { 
                    let sum = 0;
                    obj.products.forEach(element => {
                        sum += element.quantity;
                    });                    
                    return `${sum}`;
                }
            },
            rowButtons: [
                { title: ml.getText("Wyświetl"), id: "edit", to: "/orders", className: "tableButton green" },
            ]
        }
    }

    componentDidMount() {
        axios.get(`${SERVER_HOST}/order`, {
            headers: {
                "authorization": sessionStorage.token
            }
        }).then(res => {
            this.setState({ data: this.convertValues(res.data) })
        }).catch(e => {
        });
    }

    render() {
        return (
            <div>
                <h3 className="title">{this.state.title}</h3>  
                <Table
                    data={this.state.data}
                    header={this.state.header}
                    rowButtons={this.state.rowButtons}

                />              
            </div>
        )
    }
}
import ParamsHOC from "../useParamsHookHOC"
export default ParamsHOC(OrderList);