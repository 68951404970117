import React from "react"
import UserForm from "../Admin/Users/UsersForm"
import Form from "../Form"
import MLTexts from "../../MLTexts"
const ml = MLTexts.getInstance();

export default class UserBillingAddress extends UserForm {
    constructor() {
        super();
        this.state["fieldToUse"] = { "profile.billingAddress": ["city", "address", "zip", "country", "taxID", "company"] };
        this.state["params"] = {
            address: { label: ml.getText("Adres") },
            city: { label: ml.getText("Miasto") },
            zip: { label: ml.getText("Kod pocztowy") },
            country: { label: ml.getText("Kraj") },
            taxID: { label: ml.getText("NIP") },
            company: { label: ml.getText("Nazwa firmy") }
        };
    }

    render() {
        return (
            <div>
                <h3 className="title">{ml.getText("Dane do faktury")}</h3>
                <Form ref={this.form} redirectTo={"/profile"} data={this.state.data} params={this.state.params} buttons={this.state.buttons} />
            </div>
        )
    }
}