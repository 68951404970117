import React from "react"
import Form from "../../Form"
import axios from "axios"
import { SERVER_HOST } from "../../../config/global_constants"
import CategoriesForm from "./CategoriesForm"
import MLTexts from "../../../MLTexts"
const ml = MLTexts.getInstance();

class CategoriesEdit extends CategoriesForm {
    constructor(props) {
        super(props);

        this.state.buttons = [
            {
                title: ml.getText("Zapisz"), id: "edit", to: "", className: "tableButton green", onClick: (e, data, button) => {
                    e.preventDefault();
                    axios.patch(`${SERVER_HOST}/categories/${this.props.match.params.id}`, data, {
                        headers: {
                            "authorization": sessionStorage.token
                        }
                    }).then(res => {

                        if (res.status != 200) {
                            if (res.data.message) {
                                console.log(res.data.message)
                            }
                        }
                    }).catch(e => {
                        console.log(e)
                    }).finally(() => {
                        this.form.current.setState({ redirect: true });
                    })


                }
            },
            {
                title: ml.getText("Anuluj"), id: "cancel", to: "", className: "tableButton red", onClick: (e) => {
                    e.preventDefault();
                    this.form.current.setState({ redirect: true });
                }
            }
        ];
    }

    componentDidMount() {
        super.componentDidMount();

        axios.get(`${SERVER_HOST}/categories/${this.props.match.params.id}`, {
            headers: {
                "authorization": sessionStorage.token
            }
        }).then(res => {

            if (res.status != 200) {
                if (res.data.message) {
                    console.log(res.data.message)
                }
            } else {
                this.form.current.setState({ data: res.data })
            }
        }).catch(e => {
            console.log(e)
        })
    }


    render() {
        return (
            <div>
                <h3 className="title">{ml.getText("Edytuj kategorię")}</h3>
                <Form ref={this.form} redirectTo={"/admin/categories"} data={this.state.data} params={this.state.params} buttons={this.state.buttons} />
            </div>
        )
    }
}

// if want react-router-dom v6 receive route props in class
import ParamsHOC from "../../useParamsHookHOC"
export default ParamsHOC(CategoriesEdit);