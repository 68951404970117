import React, { Component } from "react"
import { Link } from "react-router-dom"
import "../css/Footer.css"
import logo from "../img/wachi-logo.png"
import MLTexts from "../MLTexts"
const ml = MLTexts.getInstance();

export default class Footer extends Component {
    render() {
        return (
            <div id="footer-container" >
                <span>
                    © 2022 <Link
                        to='#'
                        onClick={(e) => {
                            window.location = "mailto:wiktor.wachulec@edu.uni.lodz.pl?subject=Zapytanie ofertowe";
                            e.preventDefault();
                        }}
                    >Wachi Wiktor Wachulec</Link> - {ml.getText("Wszelkie prawa zastrzeżone")}.
                </span>
                <span className="footer-center"><span className={
                    localStorage.lang != "eng" ? null : "footer-lang-inactive"}
                     onClick={() => { localStorage.lang = "plk"; this.props.updateState ? this.props.updateState(): null }}>POLSKI</span> / <span className={localStorage.lang != "eng" ? "footer-lang-inactive" : null} 
                     onClick={() => { localStorage.lang = "eng"; this.props.updateState ? this.props.updateState(): null }} >ENGLISH</span></span>
                <Link to="/home"> <img src={logo} className="logo" /></Link>
            </div>
        )
    }
}

