import React from "react"
import Form from "../../Form"
import axios from "axios"
import { SERVER_HOST } from "../../../config/global_constants"
import CategoriesForm from "./CategoriesForm"
import MLTexts from "../../../MLTexts"
const ml = MLTexts.getInstance();

export default class CategoriesAdd extends CategoriesForm {
    constructor(props) {
        super(props);

        this.state.buttons = [
            {
                title: ml.getText("Dodaj"), id: "add", to: "", className: "tableButton green", onClick: (e, data, button) => {
                    e.preventDefault();
                    axios.put(`${SERVER_HOST}/categories`, data,{
                        headers: {
                            "authorization": sessionStorage.token
                        }
                    }).then(res => {
                        if (res.status != 200) {
                            if (res.data.message) {
                                alert(res.data.message)
                            }
                        }
                        this.form.current.setState({redirect: true});
                    }).catch(e => {
                        alert(e.response.data.message)
                    }).finally(() =>{
                        this.form.current.setState({ redirect: true });
                    })
                }
            },
            {
                title: ml.getText("Anuluj"), id: "cancel", to: "", className: "tableButton red", onClick: (e) => {
                    e.preventDefault();
                    this.form.current.setState({ redirect: true });
                }
            }
        ];
    }

    render() {
        return (
            <div>
                <h3 className="title">{ml.getText("Dodaj kategorię")}</h3>
                <Form ref={this.form} redirectTo={"/admin/categories"} data={this.state.data} params={this.state.params} buttons={this.state.buttons} />
            </div>
        )
    }
}