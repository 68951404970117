import React, { Component } from "react"
import "../../css/About.css"
import MLTexts from "../../MLTexts"
const ml = MLTexts.getInstance();

export default class Contact extends Component {
    constructor() {
        super();

        this.state = {

        }
    }

    render() {
        return (
            <div className="contact-view-main">
                <div className="contact-company-info">
                    <h4>{ml.getText("Dane firmy")}</h4>
                    <p>Wiktor Wachulec</p>
                    <p><strong>Wachi Wiktor Wachulec</strong></p>
                    <p>ul. Fr. Chopina 1</p>
                    <p>95-080 Tuszyn</p>
                    <p><strong>{ml.getText("NIP")}</strong>: 7282673853</p>
                </div>
                <div className="contact-contact-info">

                </div>
                <div>
                    <iframe id="map-box" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3033.00740593486!2d19.515045575718972!3d51.60784122283255!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471a303948231377%3A0x717e088d34ec7084!2sFryderyka%20Chopina%201%2C%2095-080%20Tuszyn!5e0!3m2!1spl!2spl!4v1643027760898!5m2!1spl!2spl"></iframe>
                </div>
            </div >
        )
    }
}
