import React, { Component, useEffect } from "react"
import { BrowserRouter, Routes, Route, Link, Navigate, Outlet, useNavigate } from "react-router-dom"
import "bootstrap/dist/css/bootstrap.css"
import MLTexts from "./MLTexts"
const ml = MLTexts.getInstance();

import Home from "./components/Home"
import "./css/App.css"

import TopMenu from "./components/TopMenu"
import Footer from "./components/Footer"
import LeftMenu from "./components/LeftMenu"

import LoginForm from "./components/LoginForm"
import RegistrationForm from "./components/RegistrationForm"
import Logout from "./components/Logout"

import AdminUsers from "./components/Admin/Users/UsersList"
import DeleteUser from "./components/Admin/Users/UsersDelete"
import EditUser from "./components/Admin/Users/UsersEdit"
import AddUser from "./components/Admin/Users/UsersAdd"
import UserProfile from "./components/User/UserProfile"

import CategoriesList from "./components/Admin/Categories/CategoriesList"
import EditCategories from "./components/Admin/Categories/CategoriesEdit"
import AddCategories from "./components/Admin/Categories/CategoriesAdd"
import DeleteCategories from "./components/Admin/Categories/CategoriesDelete"
import ChangeCategoriesOrder from "./components/Admin/Categories/CategoriesChangeOrder"

import ProductsListAdmin from "./components/Admin/Products/ProductsList"

import Product from "./components/Product/Product"
import DeleteProduct from "./components/Admin/Products/ProductsDelete"
import AddProduct from "./components/Admin/Products/ProductAdd"
import AddSimilarProduct from "./components/Admin/Products/ProductAddSimilar"

import EditProduct from "./components/Admin/Products/ProductEdit"
import Categories from "./components/Product/ProductList"

import About from "./components/About/About"
import Contact from "./components/About/Contact"

import Cart from "./components/Cart/Cart"
import AddToCart from "./components/Cart/CartAdd"
import DeleteFromCart from "./components/Cart/CartDelete"
import EditProductQuantityInCart from "./components/Cart/CartEdit"

import Order from "./components/Order/Order"
import OrderPayment from "./components/Order/OrderPayment"
import OrderList from "./components/Order/OrderList"
import OrderSummary from "./components/Order/SpecificOrderSummary"
import PayPalMessage from "./components/Payment/PayPalMessage"

import { ACCESS_LEVEL_GUEST, ACCESS_LEVEL_NORMAL_USER, ACCESS_LEVEL_ADMIN, SERVER_HOST } from "./config/global_constants"
import axios from "axios"
axios.defaults.withCredentials = true;

const clearSession = () => {
    sessionStorage.clear(); //localstorage
    sessionStorage.name = "GUEST"
    sessionStorage.accessLevel = ACCESS_LEVEL_GUEST
}
typeof sessionStorage.accessLevel === "undefined" ? clearSession() : null;


// catch all axios errors for redirect to login when session timeout
axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.status == 401 || error.status == 403 || (error["response"] != undefined && (error["response"].status == 403 || error["response"].status == 401))) {
        clearSession();
    }

    if (error.status == 403 || (error["response"] != undefined && error["response"].status == 403)) {    
        localStorage.lastPath = window.location.pathname;
        window.location.replace(`/login`);
    }

    return Promise.reject(error);
});


export default class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            topMenu: this.getMenu(),
            categories: [{ title: ml.getText("Wszystkie"), name: "categories", path: "#" }],
            leftMenu: [{ title:  ml.getText("Wszystkie"), name: "all", path: "#" }],
            leftMenuTitle: ml.getText("Kategorie"),
            menuChanged: false,
            url: null
        }
    }

    getMenu() {
        let accountSubMenu = [];

        if (sessionStorage.accessLevel >= ACCESS_LEVEL_ADMIN) {
            accountSubMenu.push({ title: ml.getText("Admin"), name: "admin", path: "/admin" });
        }
        if (sessionStorage.accessLevel >= ACCESS_LEVEL_NORMAL_USER) {
            accountSubMenu.push({ title: ml.getText("Profil"), name: "profile", path: "/profile" });
            accountSubMenu.push({ title: ml.getText("Zamówienia"), name: "orders", path: "/orders" });
            accountSubMenu.push({ title: ml.getText("Wyloguj"), name: "logout", path: "/logout" });
        } else {
            accountSubMenu.push({ title: ml.getText("Zaloguj"), name: "login", path: "/login" });
            accountSubMenu.push({ title: ml.getText("Zarejestruj"), name: "register", path: "/register" });

        }
        let topMenu = [
            { title: ml.getText("O nas"), name: "about", path: "/about" },
            { title: ml.getText("Kontakt"), name: "contact", path: "/contact" },
           // { title: ml.getText("Dostawa"), name: "delivery", path: "/delivery" },
            { title: ml.getText("Koszyk"), name: "cart", path: "/cart" },
            { title: ml.getText("Konto"), name: "account", path: "#", subMenu: accountSubMenu }
        ];
        return topMenu;
    }

    getCategories(callback) {
        axios.get(`${SERVER_HOST}/categories`).then(res => {
            let data = res.data.map((e) => {
                e['name'] != "all" ? e['path'] = "categories/" + e['_id'] : e['path'] = "categories/" + e['name'];
                e['title'] = ml.getText(e['title'])
                return e;
            })
            callback(data);
        }).catch(e => {
        });
    }

    componentDidMount() {
        this.getCategories((data) => {
            if (this.state["menuChanged"] === false) {
                this.setState({ categories: data, leftMenu: data });
            } else {
                this.setState({ categories: data });
            }
        })
    }

    componentDidUpdate(prevProps) {
        if (this.state.url != window.location.pathname) {
            this.setState({ url: window.location.pathname })
            this.componentDidMount();
            this.checkUserIsLoggedIn();
        }
    }

    handleUpdate = (state) => {
        if (state == undefined) {
            this.setState({ leftMenu: this.state["categories"], topMenu: this.getMenu(), leftMenuTitle: ml.getText("Kategorie"), menuChanged: false });
        } else {
            state["menuChanged"] = true;
            state["topMenu"] = this.getMenu();
            this.setState(state)
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return true;
    }

    checkUserIsLoggedIn() {
        axios.get(`${SERVER_HOST}/users/logged`).then(res => {

        }).catch(e => {
        });
    }

    render() {
        return (
            <div className="root">
                <div id="menu" ><TopMenu data={this.state.topMenu} select={this.state.categories} /> </div>
                <div id="nav"><LeftMenu title={this.state.leftMenuTitle} data={this.state.leftMenu} /></div>
                <div id="content">
                    <Routes>
                        {/* MAIN */}
                        <Route exact path="/home" component={Home} element={<Home />} />
                        <Route exact path="/login" element={<LoginForm updateState={this.handleUpdate} />} />
                        <Route exact path="/login/:path" element={<LoginForm updateState={this.handleUpdate} />} />
                        <Route exact path="/register" element={<RegistrationForm updateState={this.handleUpdate} />} />
                        <Route exact path="/logout" element={<Logout updateState={this.handleUpdate} />} />
                        <Route exact path="/profile" element={<UserProfile />} />
                        <Route exact path="/about" element={<About />} />
                        <Route exact path="/contact" element={<Contact />} />

                        {/* PRODUCT */}
                        <Route exact path="/product/:id" element={<Product />} />
                        <Route exact path="/categories/:id" element={<Categories />} />
                        <Route exact path="/categories/:id/:search" element={<Categories />} />

                        {/* CART */}
                        <Route exact path="/cart" element={<Cart />} />
                        <Route exact path="/cart/add/:id" element={<AddToCart />} />
                        <Route exact path="/cart/delete/:id" element={<DeleteFromCart />} />
                        <Route exact path="/cart/edit/:id/:quantity" element={<EditProductQuantityInCart />} />

                        {/* ORDER */}
                        <Route exact path="/order" element={<Order />} />
                        <Route exact path="/orders" element={<OrderList />} />
                        <Route exact path="/orders/:id" element={<OrderSummary />} />
                        <Route exact path="/order/payment/:id" element={<OrderPayment />} />
                        
                        <Route exact path="/PayPalMessage/:messageType/:payPalPaymentID/:orderId" element={<PayPalMessage />}/> 

                        {/* ADMIN AREA */}
                        <Route path="/admin" element={<Navigate to="/admin/products" />} />
                        <Route exact path="/admin/users" element={<AdminUsers updateState={this.handleUpdate} />} />
                        <Route exact path="/admin/users/delete/:id" element={<DeleteUser />} />
                        <Route exact path="/admin/users/edit/:id" element={<EditUser updateState={this.handleUpdate} />} />
                        <Route exact path="/admin/users/add" element={<AddUser updateState={this.handleUpdate} />} />

                        <Route exact path="/admin/categories" element={<CategoriesList updateState={this.handleUpdate} />} />
                        <Route exact path="/admin/categories/edit/:id" element={<EditCategories updateState={this.handleUpdate} />} />
                        <Route exact path="/admin/categories/add" element={<AddCategories updateState={this.handleUpdate} />} />
                        <Route exact path="/admin/categories/delete/:id" element={<DeleteCategories />} />
                        <Route exact path="/admin/categories/order/:direction/:id" element={<ChangeCategoriesOrder />} />

                        <Route exact path="/admin/products" element={<ProductsListAdmin updateState={this.handleUpdate} />} />
                        <Route exact path="/admin/products/delete/:id" element={<DeleteProduct />} />
                        <Route exact path="/admin/products/edit/:id" element={<EditProduct updateState={this.handleUpdate} />} />
                        <Route exact path="/admin/products/add/:id" element={<AddSimilarProduct updateState={this.handleUpdate} />} />
                        <Route exact path="/admin/products/add" element={<AddProduct updateState={this.handleUpdate} />} />

                        {/* REDIRECT WRONG PATHS TO HOME */}
                        <Route path="*" element={<Navigate to="/home" />} />

                        {/*   <Route exact path="/about2" component={() => <h3>InvalidURL</h3>} /> */}
                    </Routes>
                </div>
                <div id="footer">
                    <Footer updateState={()=>{this.handleUpdate(); this.componentDidMount()}}/>
                </div>
            </div>
        )
    }
}
