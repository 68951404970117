import AdminList from "../Admin/AdminList"
import axios from "axios"
import { SERVER_HOST } from "../../config/global_constants"
import ProductInList from "./ProductInList"
import MLTexts from "../../MLTexts"
const ml = MLTexts.getInstance();

class ProductList extends AdminList {
    constructor() {
        super();
        this.state = {
            data: [{ "_id": "", "name": "", "title": "", "description": "", properties: {} }],
            title: ml.getText("Produkty"),
            /*   fieldToUse: {
                   properties: ["price", "brand", "model", "condition"]
               },*/
            newFields: {
                infos: [{ name: "brand" }, { name: "model", separator: "<br/>" }]
            },
            sort: {},
            convertValue: {
                "condition": (value) => { return ml.getText(value) }
            },
            sortOptions: [
                {
                    name: "price", title: ml.getText("Cena rosnąco"), field: "price", onSelect: (member) => {
                        this.state.sort[member] = "ASC";
                    }
                },
                {
                    name: "price2", title: ml.getText("Cena malejąco"), field: "price2", onSelect: (member) => {
                        delete this.state.sort[member];
                    }
                }
            ],
            page: 1,
            perPage: 10,
            pages: 1
        }
    }

    componentDidMount() {
        let url = `${SERVER_HOST}/products?page=${this.state.page}&limit=${this.state.perPage}${this.props.match.params.id != undefined && this.props.match.params.id != "all" ? "&categories=" + this.props.match.params.id : ""}${this.props.match.params.search != undefined ? "&search=" + this.props.match.params.search : ""}`;
        axios.get(url).then(res => {
            let newData = [];
            res.data.data.forEach((d) => {
                if (d["photos"] == undefined || d["photos"].length == 0) {
                    d["image"] = `${SERVER_HOST}/products/photo/default-product.gif`;
                } else {
                    d["image"] = `${SERVER_HOST}/products/photo/${d["photos"][0]['filename']}`;
                }
                d['properties'] == undefined ? d['properties'] = {} : null;
                newData.push(this.prepareUserData(d))
            })
            this.setState({ data: this.convertValues(newData), page: res.data.page, pages: res.data.pages, currentId: this.props.match.params.id, currentSearch: this.props.match.params.search });
        }).catch(e => {
            
        });;
    }
    sort(member) {
        if (this.state.sort[member] == undefined) {
            let sortedData = this.state.data.sort((a, b) => a[member] < b[member] ? -1 : 1);
            this.setState({ data: sortedData, sort: this.state.sort })
        } else {
            let sortedData = this.state.data.sort((a, b) => a[member] < b[member] ? 1 : -1);
            this.setState({ data: sortedData, sort: this.state.sort })
        }
    }

    handleSortSelected(selection) {
        let founded = this.state.sortOptions.filter((option) => {
            return option.title == selection.target.value || option.title == selection.target.name;
        })
        if (founded.length > 0) {
            founded[0]['onSelect'] != undefined ? founded[0]['onSelect']() : null;
            this.sort(founded[0]['field']);
        }
    }

    goToPage(page) {
        if(this.state.page + page <= this.state.pages && this.state.page + page >= 1)
        this.setState({ page: this.state.page + page }, () => {
            this.componentDidMount();
        });
    }

    componentWillUnmount() {
    }

    componentDidUpdate() {

        if(this.state.currentId != this.props.match.params.id || this.state.currentSearch != this.props.match.params.search){
            this.state.page = 1;
            this.componentDidMount();
        }
            
    }

    render() {        
        return (
            <div>
                <h3 className="title">{this.state.title}</h3>
                <div className="product-list-sort-container">
                    <span>{ml.getText("Sortuj")}: </span>
                    <select name="sort" onChange={(e) => { this.handleSortSelected(e) }}>
                        <option>{ml.getText("Trafność")}</option>
                        {this.state.sortOptions.map(element =>
                            <option key={element.name}>{element.title || element.name}</option>
                        )}

                    </select>
                    <div className="product-navigate-container">
                        <div className="product-navigate-link" onClick={(e) => { this.goToPage(-1) }}>{ml.getText("Poprzednia")}</div>

                        <div>{this.state.page}</div>z<div>{this.state.pages}</div>
                        <div className="product-navigate-link" onClick={(e) => { this.goToPage(1) }}>{ml.getText("Następna")}</div>
                    </div>
                </div>
                <div className="tableContainer">
                    <table className="table">
                        <tbody>
                            {this.state.data.map(element =>
                                <tr key={element._id}><td><ProductInList data={element} /></td></tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

// if want react-router-dom v6 receive route props in class
import ParamsHOC from "../useParamsHookHOC"
export default ParamsHOC(ProductList);