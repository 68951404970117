import React, { Component } from "react"
import { Link } from "react-router-dom"

class Submenu extends Component {
    static defaultProps = 
    {
        data:[]
    }    

    render() {
        return (
            <ul className="submenu" onMouseEnter={this.props.onHover} >
                {this.props.data.map((pos) => <li key={pos.name}><Link onClick={pos.onClick ? pos.onClick : null} onMouseEnter={pos.onHover ? pos.onHover : null} to={pos.path}>{pos.title}</Link></li>)}
            </ul>
        )
    }
}

export default Submenu;