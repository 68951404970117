import React from "react"
import UserForm from "../Admin/Users/UsersForm"
import Form from "../Form"
import axios from "axios"
import { SERVER_HOST } from "../../config/global_constants"
import MD5 from "../../tools/md5generator"
import UserDeliveryAddress from "./UserDeliveryAddress"
import UserBillingAddress from "./UserBillingAddress"
import MLTexts from "../../MLTexts"
const ml = MLTexts.getInstance();

export default class UserProfile extends UserForm {
    constructor() {
        super();
        this.state.params.email['disabled'] = true;

        this.state.buttons = [
            {
                title: ml.getText("Zapisz"), id: "edit", to: "", className: "tableButton green", onClick: (e, data, button) => {
                    e.preventDefault();
                    let flatData = Object.assign({}, data);
                    data = this.setDataFromFlattened(data);

                    data["password"] != undefined && data["password"] != "" ? data["password"] = MD5(data["password"]) : delete data["password"];

                    axios.patch(`${SERVER_HOST}/users`, data, {
                        headers: {
                            "authorization": sessionStorage.token
                        }
                    }).then(res => {

                        if (res.status != 200) {
                            if (res.data.message) {
                                alert(res.data.message)
                            }
                        }else{
                            alert(ml.getText("Dane zaktualizowane"))
                            this.form.current.setState({ data: flatData })
                        }
                    }).catch(e => {
                        console.log(e)
                    }).finally(() => {
                        
                    })
                }
            }];
    }

    render() {
        return (
            <div>
                <h3 className="title">{ml.getText("Profil")}</h3>
                <Form ref={this.form} redirectTo={"/profile"} data={this.state.data} params={this.state.params} buttons={this.state.buttons} />
                <UserDeliveryAddress />
                <UserBillingAddress />
            </div>
        )
    }
}