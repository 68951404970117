import React, { Component } from "react"
import "../css/ModalPhoto.css"

export default class ModalPhoto extends Component {

  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <div id="modal" onClick={() => { this.props.closeModal() }}>
        <div id="modalContent">
          <img src={this.props.src} />
        </div>
      </div>
    )
  }
}