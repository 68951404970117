import React, { Component } from "react"
import { ACCESS_LEVEL_ADMIN } from "../../config/global_constants"
import { Navigate } from "react-router-dom"
var _ = require('lodash');
import MLTexts from "../../MLTexts"
const ml = MLTexts.getInstance();

export default class Admin extends Component {
    _isMounted = false;

    constructor() {
        super();
    }

    componentDidMount() {
        this._isMounted = true;
        this.props.updateState != undefined ? this.props.updateState(
            {
                leftMenu: [
                    { title: ml.getText("Produkty"), name: "products", path: "/admin/products" },
                    { title: ml.getText("Użytkownicy"), name: "users", path: "/admin/users" },
                    { title: ml.getText("Kategorie"), name: "categories", path: "/admin/categories" }
                ],
                leftMenuTitle: "Admin"
            }
        ) : null;
    }

    setDataFromFlattened (data){
        Object.keys(this.state.fieldToUse).forEach((key) => {
            if (_.get(this.state.fieldToUse, key).length > 0) {
                _.get(this.state.fieldToUse, key).forEach((subKey) => {
                    
                    if (data[subKey] != undefined) {
                        _.get(data, key) == undefined ? _.set(data, key, {}) : null;
                        _.get(data, key)[subKey] = _.get(data, subKey);
                        delete _.get(data, subKey);
                    }
                })
            }
        })
        return data;
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.props.updateState != undefined ? this.props.updateState() : null;
    }

    prepareUserData(data) { // to splaszcza do rodzica dodac i sprawdzic newField
        if (this.state.fieldToUse != undefined)
            Object.keys(this.state.fieldToUse).forEach((key) => {                
                if (_.get(data, key) != undefined && _.get(this.state.fieldToUse, key).length > 0) {
                    _.get(this.state.fieldToUse, key).forEach((subField) => {                  
                        data[subField] = _.get(data, key)[subField];
                    })
                    delete _.get(data, key);
                }
            })

            if (this.state.newFields != undefined)
            Object.keys(this.state.newFields).forEach((newField) => {
                data[newField] = "";
                this.state.newFields[newField].forEach((field) => {
                    let separator = field.separator != undefined ? field.separator : " ";
                    data[newField] += separator;
                    if(data[field.name] != undefined)
                        data[newField] += data[field.name];
                })

            })
        return data;
    }

    render() {
        return (
            <div>
                {sessionStorage.accessLevel >= ACCESS_LEVEL_ADMIN ? null :
                    <Navigate to="/home" />
                }
            </div>
        )
    }
}