//Higher Order Components
import { useParams, useNavigate, useLocation } from "react-router-dom"
 export default (Component) => {
    return (props) => {
        const match = { params: useParams() };
        return (
            <Component {...props} match={match} navigate={useNavigate()} location={useLocation()} />
        );
    };
};

/*
export const withHooksHOC = (Component) => {

    return (props) => {
        const match = { params: useParams() };

        return (
            <Component {...props} match={match} />
        );
    };
};
*/