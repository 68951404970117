import React from "react"
import Form from "../../Form"
import axios from "axios"
import { SERVER_HOST } from "../../../config/global_constants"
import MD5 from "../../../tools/md5generator"
import UsersForm from "./UsersForm"
import MLTexts from "../../../MLTexts"
const ml = MLTexts.getInstance();

export default class UsersAdd extends UsersForm {
    constructor(props) {
        super(props)

        this.state.buttons = [
                { title: ml.getText("Dodaj"), id: "add", to: "", className: "tableButton green" , onClick: (e, data, button)=>{
                    e.preventDefault();
                    
                    Object.keys(this.state.fieldToUse).forEach((key)=>{
                        if(this.state.fieldToUse[key].length>0){
                            this.state.fieldToUse[key].forEach((subKey)=>{
                                if(data[subKey] != undefined){
                                    data[key] == undefined ? data[key] = {} : null;
                                    data[key][subKey] = data[subKey];
                                    delete data[subKey];
                                }                                
                            })
                        }
                    })

                    axios.post(`${SERVER_HOST}/users/register/${data.email}/${MD5(data.password)}`, data,{
                        headers: {
                            "authorization": sessionStorage.token
                        }
                    }).then(res => {
                        if (res.status != 200) {
                            if (res.data.message) {
                                alert(res.data.message)
                            }
                        }
                        this.form.current.setState({redirect: true});
                    }).catch(e => {
                        alert(e.response.data.message)
                    }).finally(() =>{
                        
                    })

                }},
                { title: "Anuluj", id: "cancel", to: "", className: "tableButton red", onClick: (e)=>{
                    e.preventDefault();
                    this.form.current.setState({redirect: true});
                } }
            ];
    }

    componentDidMount() {
    }

    render() {        
        return (
            <div>
                <h3 className="title">Dodaj usera</h3>
                <Form ref={this.form} redirectTo={"/admin/users"} data={this.state.data} params={this.state.params} buttons={this.state.buttons}/>
            </div>
        )
    }
}
