import React, { Component } from "react"
import { Navigate } from "react-router-dom"
import axios from "axios"
import { ACCESS_LEVEL_GUEST, SERVER_HOST, ACCESS_LEVEL_NORMAL_USER } from "../config/global_constants"

class Logout extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoggedIn: sessionStorage.accessLevel >= ACCESS_LEVEL_NORMAL_USER
        }
    }

    componentDidMount() {
        axios.post(`${SERVER_HOST}/users/logout`, {}, {
            headers: {
                "authorization": sessionStorage.token
            }
        }).then(res => {
            res.data && res.data.message ?
                    console.log(res.data.message) : null;
        }).catch(e => {
            
        }).finally(() => {
            sessionStorage.clear()
            sessionStorage.name = "GUEST"
            sessionStorage.accessLevel = ACCESS_LEVEL_GUEST
            this.props.location.key !== "default" ?
            this.props.navigate(-1) :
            this.props.navigate("/home");
        })
    }

    componentWillUnmount() {
        this.props.updateState != undefined ? this.props.updateState() : null;
    }

    render() {
        return (
            <div>
                {!this.state.isLoggedIn ? <Navigate to="/home" /> : null}
            </div>
        )
    }
}

import ParamsHOC from "./useParamsHookHOC"
export default ParamsHOC(Logout);