import React from "react"
import Form from "../../Form"
import axios from "axios"
import { SERVER_HOST } from "../../../config/global_constants"
import ProductAdd from "./ProductAdd"


class AddSimilarProduct extends ProductAdd {
    constructor(props) {
        super(props)
    }

    convertValues(element) {
        Object.keys(element).forEach((key) => {
            if (this.state.convertValue[key] != undefined) {
                element[key] = this.state.convertValue[key](element[key]);
            }
            return element;
        })
        return element;
    }



    componentDidMount() {
        super.componentDidMount();
        axios.get(`${SERVER_HOST}/products/${this.props.match.params.id}`, {
            headers: {
                "authorization": sessionStorage.token
            }
        }).then(res => {

            if (res.status != 200) {
                if (res.data.message) {
                    console.log(res.data.message)
                }
            } else {
                res.data['name'] = res.data['name'] + "_";
                this.form.current.setState({
                    data: this.convertValues(this.prepareUserData(res.data))
                })

            }
        }).catch(e => {
            console.log(e)
        })
    }

}


// if want react-router-dom v6 receive route props in class
import ParamsHOC from "../../useParamsHookHOC"
export default ParamsHOC(AddSimilarProduct);