import React, { Component } from "react"
import "../css/LoginForm.css"
import { Link, Navigate } from "react-router-dom"
import axios from "axios"
import { ACCESS_LEVEL_NORMAL_USER, SERVER_HOST } from "../config/global_constants"
import MD5 from "../tools/md5generator"
import MLTexts from "../MLTexts"
const ml = MLTexts.getInstance();

class LoginForm extends Component {
    lastPath = null;

    constructor(props) {
        super(props)
        this.state =
        {
            email: "",
            password: "",
            confirmPassword: "",
            wasSubmittedAtLeastOnce: false,
            responseCode: 200,
            isLoggedIn: sessionStorage.accessLevel >= ACCESS_LEVEL_NORMAL_USER
        }
    }

    validateEmail() {
        const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(String(this.state.email).toLowerCase())
    }

    validate() {
        return {
            email: this.state.email == "" || this.validateEmail()
        }
    }

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleSubmit = e => {
        this.setState({ wasSubmittedAtLeastOnce: true })
        const formInputsState = this.validate();
        if (Object.keys(formInputsState).every(index => formInputsState[index])) {
            axios.post(`${SERVER_HOST}/users/login/${this.state.email}/${MD5(this.state.password)}`)
                .then(res => {
                    if (res.data) {
                        sessionStorage.name = res.data.name;
                        sessionStorage.accessLevel = res.data.accessLevel;
                        sessionStorage.token = res.data.token;
                        this.setState({ isLoggedIn: true, responseCode: res.status });
                    } else {
                        this.setState({ responseCode: res.status });
                    }
                }).catch(e => {
                    if (e["response"] != undefined) {
                        this.setState({ responseCode: e["response"].status });
                    } else {
                        this.setState({ responseCode: 504 });
                    }
                })
        }
        else {
            e.preventDefault()
            return
        }
    }

    componentDidUpdate() {
        if (localStorage.lastPath) { this.lastPath = localStorage.lastPath; delete localStorage.lastPath };
    }

    componentWillUnmount() {
        this.props.updateState != undefined ? this.props.updateState() : null;
    }

        onKeyPressed(e){
            if(e.key == "Enter")
                this.handleSubmit(e);
        }

    render() {
        const formInputsState = this.validate();
        const inputsAreAllValid = Object.keys(formInputsState).every(index => {
            return formInputsState[index] && this.state[index] != "";
        });
        const emailErrorMsg = ml.getText("Nieprawidłowy email!");
        let responseMsg = "";

        if (this.state['responseCode'] != 200) {
            responseMsg = ml.getText("Błąd połączenia z serwerem!");
        } else if (this.state['wasSubmittedAtLeastOnce']) {
            responseMsg = ml.getText("Zalogowano pomyślnie!");
        }

        if (this.state['responseCode'] == 401) {
            responseMsg = ml.getText("Błędny login lub hasło!");
        }

        return (
            <div className="login-container"><p>{ml.getText("Zaloguj")} / <Link to="/register">{ml.getText("Zarejestruj")}</Link></p>
                <form id="loginForm" onSubmit={this.handleSubmit} onKeyDown={(e) => {this.onKeyPressed(e)}}
                tabIndex="0" >
                    <input
                        name="email"
                        className={formInputsState.email ? "" : "error"}
                        type="text"
                        placeholder={ml.getText("eMail")}
                        value={this.state.email}
                        onChange={this.handleChange}
                        ref={input => this.inputToFocus = input}
                    />
                    <span className={formInputsState.email ? "" : "error"}>{formInputsState.email ? "" : emailErrorMsg}</span>
                    <input
                        name="password"
                        type="password"
                        placeholder={ml.getText("Password")}
                        value={this.state.password}
                        onChange={this.handleChange}
                    />
                    <input
                        type="button"
                        value={ml.getText("Zaloguj")}
                        disabled={!inputsAreAllValid}
                        onClick={this.handleSubmit}
                    />
                    <span className={this.state['responseCode'] == 200 ? "good" : "error"}>{responseMsg == "" ? "" : responseMsg}</span>
                </form>
                {this.state.isLoggedIn ?
                    this.lastPath ? <Navigate to={this.lastPath} /> :
                        this.props.location.key !== "default" ?
                            this.props.navigate(-1) :
                            <Navigate to="/home" />
                    : null}
            </div>
        )
    }
}

import ParamsHOC from "./useParamsHookHOC"
export default ParamsHOC(LoginForm);