import React, { Component } from "react"
import "../css/LoginForm.css"
import { Navigate, Link } from "react-router-dom"
import { ACCESS_LEVEL_NORMAL_USER, SERVER_HOST } from "../config/global_constants"
import axios from "axios"
import MD5 from "../tools/md5generator"
import MLTexts from "../MLTexts"
const ml = MLTexts.getInstance();

export default class RegistrationForm extends Component {
    constructor() {
        super()

        this.state =
        {
            email: "",
            password: "",
            confirmPassword: "",
            wasSubmittedAtLeastOnce: false,
            responseCode: 200,
            responseMsg: "",
            isLoggedIn: sessionStorage.accessLevel >= ACCESS_LEVEL_NORMAL_USER
        }
    }

    validateEmail() {
        const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(String(this.state.email).toLowerCase())
    }

    validatePassword() {
        const pattern = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[£!#€$%^&*]).{10,}$/
        return pattern.test(String(this.state.password))
    }

    validateConfirmPassword() {
        return (this.state.password === this.state.confirmPassword)
    }

    validate() {
        return {
            email: this.state.email == "" || this.validateEmail(),
            password: this.state.password == "" || this.validatePassword(),
            confirmPassword: this.validateConfirmPassword()
        }
    }

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleSubmit = e => {
        this.setState({ wasSubmittedAtLeastOnce: true })
        const formInputsState = this.validate();
        if (Object.keys(formInputsState).every(index => formInputsState[index])) {
            axios.post(`${SERVER_HOST}/users/register/${this.state.email}/${MD5(this.state.password)}`)
                .then(res => {
                    if (res.data) {
                        sessionStorage.name = res.data.name;
                        sessionStorage.accessLevel = res.data.accessLevel;
                        sessionStorage.token = res.data.token;
                        this.setState({ isLoggedIn: true })
                    }
                    this.setState({ responseCode: res.status });
                }).catch(e => {
                    if (e["response"] != undefined) {
                        if (e["response"].data != undefined && e["response"].data.message != undefined) {
                            this.setState({ responseCode: e["response"].status, responseMsg: e["response"].data.message });

                        } else {
                            this.setState({ responseCode: e["response"].status, responseMsg: "" });
                        }

                    } else {
                        this.setState({ responseCode: 504 });
                    }
                })
        }
        else {
            e.preventDefault()
            return
        }
    }

    componentWillUnmount(){
        this.state.isLoggedIn && this.props.updateState != undefined ? this.props.updateState() : null;
    }

    render() {
        const formInputsState = this.validate();
        const inputsAreAllValid = Object.keys(formInputsState).every(index => {
            return formInputsState[index] && this.state[index] != "";
        });
        const emailErrorMsg = ml.getText("Nieprawidłowy email!");
        let passwordErrorMsg = ml.getText("Wrong password confirmation!");
        let passwordErrorList = []

        if (this.state.password.length < 10) {
            passwordErrorList.push(ml.getText("Password is too short!"))
        }
        if (!this.state.password.match(/[0-9]/)) {
            passwordErrorList.push(ml.getText("Password must contain at least one digit (0-9)."))
        }
        if (!this.state.password.match(/[a-z]/)) {
            passwordErrorList.push(ml.getText("Password must contain at least one lowercase character."))
        }
        if (!this.state.password.match(/[A-Z]/)) {
            passwordErrorList.push(ml.getText("Password must contain at least one uppercase character."))
        }
        if (!this.state.password.match(/[£!#€$%^&*]/)) {
            passwordErrorList.push(ml.getText("Password must contain at least one of the characters £!#€$%^&*"))
        }

        passwordErrorList = passwordErrorList.map(e => <li> {e} </li>)

        if (this.state.responseMsg == "") {
            if (this.state['responseCode'] != 200) {
                this.state.responseMsg = ml.getText("Błąd połączenia z serwerem!");
            } else if (this.state['wasSubmittedAtLeastOnce']) {
                this.state.responseMsg = ml.getText("Zalogowano pomyślnie!");
            }

            if (this.state['responseCode'] == 401) {
                this.state.responseMsg = ml.getText("Błędny login lub hasło!");
            }
        }

        return (
            <div className="login-container"><p><Link to="/login">{ml.getText("Zaloguj")}</Link> / {ml.getText("Zarejestruj")}</p>
                <form id="loginForm" onSubmit={this.handleSubmit}>
                    <input
                        name="email"
                        className={formInputsState.email ? "" : "error"}
                        type="text"
                        placeholder={ml.getText("eMail")}
                        value={this.state.email}
                        onChange={this.handleChange}
                        ref={input => this.inputToFocus = input}
                    />
                    <span className={formInputsState.email ? "" : "error"}>{formInputsState.email ? "" : emailErrorMsg}</span>
                    <input
                        name="password"
                        className={formInputsState.password ? "" : "error"}
                        type="password"
                        placeholder={ml.getText("Password")}
                        value={this.state.password}
                        onChange={this.handleChange}
                    />
                    <ul>{formInputsState.password ? "" : passwordErrorList}</ul>
                    <input
                        name="confirmPassword"
                        className={formInputsState.confirmPassword ? "" : "error"}
                        type="password"
                        placeholder={ml.getText("Confirm password")}
                        value={this.state.confirmPassword}
                        onChange={this.handleChange}
                    />
                    <span className={formInputsState.confirmPassword ? "" : "error"}>{formInputsState.confirmPassword ? "" : passwordErrorMsg}</span>
                    <input
                        type="button"
                        value={ml.getText("Register")}
                        disabled={!inputsAreAllValid}
                        onClick={this.handleSubmit}
                    />
                    <span className={this.state['responseCode'] == 200 ? "good" : "error"}>{this.state.responseMsg == "" ? "" : this.state.responseMsg}</span>
                    {this.state.isLoggedIn ? <Navigate to="/home" /> : null}
                </form>
            </div>
        )
    }
}