//export const SERVER_HOST = `http://localhost:4326`
export const SERVER_HOST = `/api`

// Access Levels
export const ACCESS_LEVEL_GUEST = 0
export const ACCESS_LEVEL_NORMAL_USER = 2
export const ACCESS_LEVEL_ADMIN = 5

// PayPal
export const SANDBOX_CLIENT_ID = "AWRGw_FTz3QiAEcQs-dvgGM4SPv0Za_Ff3F_K1tK2Ju9W7rhzSb9VOF-tx6_0d16Sa8xExsOGQvupsz8"
export const PRODUCTION_CLIENT_ID = "TO_MAKE_A_LIVE_REAL_WORLD_APPLICATION_REPLACE_THIS_TEXT_WITH_YOUR_PRODUCTION_CLIENT_ID"