import React, { Component } from "react"
import axios from "axios"
import { Navigate } from "react-router-dom"
import { SANDBOX_CLIENT_ID, PRODUCTION_CLIENT_ID, SERVER_HOST } from "../../config/global_constants"
import PaypalButton from 'react-paypal-express-checkout'

export default class PayPalPayment extends Component {
    constructor(props) {
        super(props)

        this.state = {
            redirectToPayPalMessage: false,
            payPalMessageType: null,
            payPalPaymentID: null
        }

        this.messageType = {
            SUCCESS: "success",
            ERROR: "error",
            CANCEL: "cancel"
        }
    }

    onSuccess = paymentData => {
        axios.post(`${SERVER_HOST}/order/payment/${this.props.orderId}`, paymentData, {
            headers: {
                "authorization": localStorage.token
            }
        }).then(res => {
            this.setState({
                payPalMessageType: this.messageType.SUCCESS,
                payPalPaymentID: paymentData.paymentID,
                redirectToPayPalMessage: true
            })
        }).catch(errorData => {
            console.log("PayPal payment unsuccessful error:", errorData)
            this.setState({
                payPalMessageType: this.messageType.ERROR,
                redirectToPayPalMessage: true
            })
        })
    }

    onError = errorData => {
        console.log("PayPal error:", errorData)
        this.setState({
            payPalMessageType: this.messageType.ERROR,
            redirectToPayPalMessage: true
        })
    }

    onCancel = cancelData => {
        // The user pressed the Paypal checkout popup window cancel button or closed the Paypal checkout popup window
        console.log("Payment cancelled by user:", cancelData)
        this.setState({
            payPalMessageType: this.messageType.CANCEL,
            redirectToPayPalMessage: true
        })
    }

    render() {
        const environment = "sandbox"  // must be either "sandbox" or "production"

        const client_id = {
            sandbox: SANDBOX_CLIENT_ID,
            production: PRODUCTION_CLIENT_ID
        }

        const redirect = `/PayPalMessage/${this.state.payPalMessageType}/${this.state.payPalPaymentID}/${this.props.orderId}`
        return (
            <div>
                <div>
                    {this.state.redirectToPayPalMessage ? <Navigate to={redirect} /> : null}

                    <PaypalButton
                        env={environment}
                        client={client_id}

                        currency="PLN"
                        total={this.props.price}

                        onSuccess={this.onSuccess}
                        onError={this.onError}
                        onCancel={this.onCancel}

                        style={{ size: "small", color: "blue" }}
                    />
                </div>

            </div>
        )
    }
}