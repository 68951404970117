import React, { Component } from "react"
import TableRow from "./TableRow"
import "../css/Table.css"
import { Link } from "react-router-dom"

export default class Table extends Component {

    static defaultProps =
        {
            data: [],
            header: []
        }


    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div className="tableContainer">
                <table className={this.props.className || "table"}>
                    {this.props.header != undefined && this.props.header.length > 0 ?
                        <thead>
                            <tr>
                                {this.props.header.map(col =>
                                    <th
                                        key={col.name}
                                        onClick={this.props.handleHeaderClick == undefined ? null : ((e) => this.props.handleHeaderClick(e, col))}
                                    >
                                        {col.title}
                                    </th>
                                )}
                                {this.props.rowButtons != undefined ?
                                    <th></th>
                                    : null}
                            </tr>
                        </thead>
                        : null}
                    <tbody>
                        {this.props.data.map(row => <TableRow key={row._id} data={row} header={this.props.header} handleClick={this.props.handleRowClick} buttons={this.props.rowButtons} />)}
                    </tbody>
                </table>
                {this.props.buttons != undefined ?
                    this.props.buttons.map(button =>
                        <Link key={button.id} className={button.className} onClick={button.onClick == undefined ? null : ((e) => button.onClick(e, this.props.data, button))} to={button.to}>{button.title}</Link>
                    )
                    : null}
            </div>
        )
    }
}