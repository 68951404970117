import React from "react"
import Form from "../../Form"
import Admin from "../Admin"
import axios from "axios"
import { SERVER_HOST } from "../../../config/global_constants"
import MLTexts from "../../../MLTexts"
const ml = MLTexts.getInstance();

export default class UsersForm extends Admin {
    constructor(props) {
        super(props)

        this.state = {
            data: {},
            fieldToUse: {
                email: [],
                profile: ["firstName", "familyName", "companyName", "phone"]
            },
            params: {
                email: { label: ml.getText("eMail"), onClick: (e) => { } },
                password: { label: ml.getText("Nowe hasło"), type: "password" },
                firstName: { label: ml.getText("Imię"), type: "text" },
                familyName: { label: ml.getText("Nazwisko") },
                companyName: { label: ml.getText("Firma") },
                phone: { label: ml.getText("Telefon") }

            },
            redirectToList: false
        }

        this.state.buttons = [
            {
                title: ml.getText("Zapisz"), id: "edit", to: "", className: "tableButton green", onClick: (e, data, button) => {
                    e.preventDefault();
                    let flatData = Object.assign({}, data);
                    data = this.setDataFromFlattened(data);

                    axios.patch(`${SERVER_HOST}/users`, data, {
                        headers: {
                            "authorization": sessionStorage.token
                        }
                    }).then(res => {

                        if (res.status != 200) {
                            if (res.data.message) {
                                alert(res.data.message)
                            }
                        } else {
                            alert(ml.getText("Dane zaktualizowane"))
                            this.form.current.setState({ data: flatData })

                        }
                    }).catch(e => {
                        console.log(e)
                    }).finally(() => {

                    })
                }
            }];

        this.form = React.createRef();
    }

    componentDidMount() {
        super.componentDidMount();
        axios.get(`${SERVER_HOST}/users/profile`, {
            headers: {
                "authorization": sessionStorage.token
            }
        }).then(res => {
            this.form.current.setState({ data: this.prepareUserData(res.data) })
            this.setState({ id: res.data._id })
        }).catch(e => {
        });;
    }

    render() {
        return (
            <div>
                <Form ref={this.form} redirectTo={"/admin/users"} data={this.state.data} params={this.state.params} buttons={this.state.buttons} />
            </div>
        )
    }
}