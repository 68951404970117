import React, { Component } from "react"
import logo from "../img/wachi-logo.png"
import "../css/TopMenu.css"
import { Link } from "react-router-dom"
import SubMenu from "./SubMenu"
import MLTexts from "../MLTexts"
const ml = MLTexts.getInstance();

class TopMenu extends Component {

    constructor(props) {
        super(props)

        this.state = {
            combobox: "all",
            searchText: ""
        }
    }

    static defaultProps =
        {
            data: [],
            select: [{ title: ml.getText("Wszystkie"), name: "all", path: "#" }],
            placeholder: ml.getText("Czego szukasz?")
        }

    searchProducts() {
        let url = `/categories/${this.state.combobox}`;
        if (this.state.searchText != "") {
            url += `/${this.state.searchText}`
        }
        this.props.navigate(url);
    }

    onKeyPressed(e){
        if(e.key == "Enter")
            this.searchProducts();
    }

    render() {
        return (
            <div className="top-menu">
                <div id="logo"><Link to="/home"><img src={logo} className="logo" /></Link></div>
                <div id="searchContainer"><div id="searchBarContainer"><input onChange={(e) => {
                    this.setState({ searchText: e.target.value })
                }}     onKeyDown={(e) => {this.onKeyPressed(e)}}
                tabIndex="0" required="" type="text" autoComplete="off" name="string" role="combobox" aria-autocomplete="both" aria-controls="suggestions-listbox" data-role="search-input" placeholder={this.props.placeholder} autoFocus="" />
                    <select name="search" onChange={(e) => {
                        this.setState({ combobox: e.target.value.replace("categories/", "") })
                    }}>
                        {this.props.select.map((pos) =>
                            <option key={pos.name} value={pos.path}>{pos.title}</option>)}
                    </select>
                    <button onClick={(e) => { this.searchProducts(e) }}>{ml.getText("Szukaj")}</button></div>
                </div>
                <nav>
                    <ul>
                        {this.props.data.map((pos) => <li key={pos.name}><Link onMouseEnter={pos.onHover ? pos.onHover : null} onClick={pos.onClick ? pos.onClick : null} to={pos.path}>{pos.title}</Link>{pos.subMenu ? <SubMenu data={pos.subMenu} /> : null}</li>)}
                    </ul>
                </nav>
            </div>
        )
    }
}

import ParamsHOC from "./useParamsHookHOC"
export default ParamsHOC(TopMenu);