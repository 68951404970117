import React, { Component } from "react"
import { Link } from "react-router-dom"
import parse from 'html-react-parser'

export default class TableRow extends Component {

    static defaultProps =
        {
            data: []
        }

    constructor(props) {
        super(props)
    }

    render() {
        return (
            <tr key={this.props.data._id}>
                {this.props.header.map(headField =>
                    <td
                        onClick={ this.props.handleClick == undefined ? (null) : ((e) => this.props.handleClick(e, this.props.data, headField.field)) }
                        onChange={ this.props.handleClick == undefined ? (null) : ((e) => this.props.handleClick(e, this.props.data, headField.field)) }
                        key={headField.field + "_" + this.props.data._id}  className={headField.className}>     
                        {headField.compile == true && this.props.data[headField.field] != undefined ? parse(this.props.data[headField.field]) : this.props.data[headField.field]}
                    </td>
                )}
                {this.props.buttons != undefined ? 
                    <td>
                    {this.props.buttons.map(button => 
                        <Link key={button.id} className={button.className} onClick={ button.onClick == undefined ? null : ((e) => button.onClick(e, this.props.data, button)) } to={button.to + "/" + this.props.data._id}>{button.title}</Link>                    
                    )}
                    </td>
                 : null}
            </tr>
        )
    }
}