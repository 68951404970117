import React, { Component } from "react"
import axios from "axios"
import { Navigate } from "react-router-dom"
import { SERVER_HOST } from "../../config/global_constants"


class CartEdit extends Component {
    constructor(props) {
        super(props)

        this.state = {
            redirect: false
        }
    }

    componentDidMount() {

        let url = `${SERVER_HOST}/cart`;

        localStorage.cart!=undefined ? url += `/${localStorage.cart}` : null;
        url += `/${this.props.match.params.id}`;

        axios.patch(url, {quantity: parseInt(this.props.match.params.quantity)}).then(res => {
            res.data["_id"]!=undefined ? localStorage.cart = res.data["_id"] : null;
            this.setState({redirect: true})
        }).catch(e => {
            delete localStorage.cart;            
        })
    }

    render() {
        return (
            <div>               
                {this.state.redirect ? <Navigate to="/cart" replace /> : null}
            </div>
        )
    }
}

// if want react-router-dom v6 receive route props in class
import ParamsHOC from "../useParamsHookHOC"
export default ParamsHOC(CartEdit);