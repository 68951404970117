import React from "react"
import ReactDOM from "react-dom"
import App from "./App.js"
import { BrowserRouter } from 'react-router-dom';
const getLanguage = () => navigator.userLanguage || (navigator.languages && navigator.languages.length && navigator.languages[0]) || navigator.language || navigator.browserLanguage || navigator.systemLanguage || 'en';
localStorage.lang ? null :localStorage.lang = getLanguage() == "pl" || getLanguage() == "pl_PL" ? "plk" : "eng";
//ReactDOM.render(<App/>, document.getElementById(`app`))

ReactDOM.render(
    <BrowserRouter>
      <App/>
    </BrowserRouter>,
    document.getElementById('app')
  )
  