import List from "../AdminList"
import axios from "axios"
import { SERVER_HOST } from "../../../config/global_constants"
import MLTexts from "../../../MLTexts"
const ml = MLTexts.getInstance();

export default class CategoriesList extends List {
    constructor() {
        super();
        this.state = {
            data: [{ "_id": "", "name": "", "title": "", "description": "" }],
            title: ml.getText("Kategorie"),
            header: [
                { name: "name", title: ml.getText("Nazwa"), field: "name" },
                { name: "title", title: ml.getText("Etykieta"), field: "title" },
                { name: "description", title: ml.getText("Opis"), field: "description" }
            ],
            rowButtons: [
                { title: ml.getText("Edytuj"), id: "edit", to: "edit", className: "tableButton green" },
                { title: ml.getText("Usuń"), id: "delete", to: "delete", className: "tableButton red" },
                { title: `Up`, id: "up", to: "order/up", className: "tableButton arrow arrow-up" },
                { title: "Do", id: "down", to: "order/down", className: "tableButton arrow" }
            ],
            tableButtons: [
                { title: ml.getText("Dodaj"), id: "add", to: "add", className: "tableButton" }
            ],
            sort: {}
        }
    }

    handleRowClick = (e, data, field) => {
    }

    handleHeaderClick = (e, col) => {
    }

    componentDidMount() {
        super.componentDidMount();
        axios.get(`${SERVER_HOST}/categories`).then(res => {
            this._isMounted ?
                this.setState({ data: res.data })
                : null
        }).catch(e => {
        })
    }
}