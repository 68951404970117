import React from "react"
import Form from "../../Form"
import axios from "axios"
import { SERVER_HOST } from "../../../config/global_constants"
import Admin from "../Admin"
import MLTexts from "../../../MLTexts"
const ml = MLTexts.getInstance();

export default class ProductAdd extends Admin {
    constructor(props) {
        super(props)


        this.state = {
            data: {},
            fieldToUse: {
                name: [],
                properties: ["brand", "model", "condition", "weight", "price", "tax"],
                category: [],
                description: [],
                title: [],
                quantity: [],      
                catalog: []
            },
            params: {
                name: { label: ml.getText("Nazwa"), onClick: (e) => { } },
                title: { label: ml.getText("Tytuł") },
                category: { label: ml.getText("Kategoria"), type: "select", values: [{ _id: "", title: "" }] },
                catalog: { label: ml.getText("Katalog"), type: "text" },
                description: { label: ml.getText("Opis"), className: "add-product-form-field", type: "textarea" },
                brand: { label: ml.getText("Marka") },
                model: { label: ml.getText("Model") },
                weight: { label: ml.getText("Waga") + " (g)" },
                quantity: { label: ml.getText("Ilość") },
                price: { label: ml.getText("Cena"), type: "number" },
                tax: { label: ml.getText("VAT") },
                photos: { label: ml.getText("Zdjęcia"), type: "file" }
            },
            convertValue: {
                "category": (value) => {
                    let found = this.state['params']['category']['values'].filter(function (f) { return f._id == value });

                    return { _id: value, title: found.length > 0 ? found[0].title : value }
                }
            },
            redirectToList: false
        }

        this.form = React.createRef();

        this.state.buttons = [
            {
                title: ml.getText("Dodaj"), id: "add", to: "", className: "tableButton green", onClick: (e, data, button) => {
                    e.preventDefault();

                    Object.keys(this.state.fieldToUse).forEach((key) => {
                        if (this.state.fieldToUse[key].length > 0) {
                            this.state.fieldToUse[key].forEach((subKey) => {
                                if (data[subKey] != undefined) {
                                    data[key] == undefined ? data[key] = {} : null;
                                    data[key][subKey] = data[subKey];
                                    delete data[subKey];
                                }
                            })
                        }
                    })

                    delete data['photos'];

                    const addPhoto = (id, callback) => {
                        let formData = new FormData()
                        for (let i = 0; i < data.files.length; i++) {
                            formData.append("files", data.files[i])
                        }
                        axios.post(`${SERVER_HOST}/products/photo/${id}`, formData, {
                            headers: {
                                "authorization": sessionStorage.token,
                                "Content-type": "multipart/form-data"
                            }
                        }).then(res => {
                            if (res.status != 200) {
                                if (res.data.message) {
                                    alert(res.data.message)
                                }
                            } else {
                                callback();
                            }
                        }).catch(e => {
                            alert(e.response.data.message)
                        }).finally(() => {

                        })
                    }

                    !data.properties ? data.properties ={}:null;
                    !data.properties.price ? data.properties.price = 0 : null;
                    !data.properties.tax ? data.properties.tax = 0 : null;
                    !data.properties.weight ? data.properties.weight = 0 : null;

                    axios.put(`${SERVER_HOST}/products`, data, {
                        headers: {
                            "authorization": sessionStorage.token
                        }
                    }).then(res => {
                        if (res.status != 200) {
                            if (res.data.message) {
                                alert(res.data.message)
                            }
                        }
                        if (data.files != undefined && data.files.length > 0) {
                            addPhoto(res.data._id, () => {
                                this.form.current.setState({ redirect: true });
                            })

                        } else {
                            this.form.current.setState({ redirect: true });
                        }

                    }).catch(e => {
                        alert(e.response.data.message)
                    }).finally(() => {

                    })

                }
            },
            {
                title: ml.getText("Anuluj"), id: "cancel", to: "", className: "tableButton red", onClick: (e) => {
                    e.preventDefault();
                    this.form.current.setState({ redirect: true });
                }
            }
        ];
    }

    prepareUserData(data) {
        let fieldToUse = Object.keys(data).filter((field) => {
            if (this.state.fieldToUse[field] != undefined) {
                return true;
            }
            return false;
        })

        let correctData = {};

        fieldToUse.forEach((field) => {
            if (this.state.fieldToUse[field].length > 0) {
                this.state.fieldToUse[field].forEach((subField) => {
                    if (data[field][subField] != undefined) {
                        correctData[subField] = data[field][subField];
                    }
                })
            } else {
                correctData[field] = data[field];
            }

        })
        return correctData;
    }

    componentDidMount() {
        super.componentDidMount();
        axios.get(`${SERVER_HOST}/categories`).then(cat => {
            let categories = [];
            cat.data.forEach((category)=>{
                category['title'] = ml.getText(category['title']);
                categories.push(category);                
            })
            this.state['params']['category']['values'] = categories;
            this.setState(this.state)
        }).catch(e => {
            console.log(e)
        })
    }

    render() {
        return (
            <div>
                <h3 className="title">{ml.getText("Dodaj produkt")}</h3>
                <Form ref={this.form} redirectTo={"/admin/products"} data={this.state.data} params={this.state.params} buttons={this.state.buttons} />
            </div>
        )
    }
}


