import React from "react"
import Table from "../Table"
import List from "../Admin/AdminList"
import "../../css/OrderSummary.css"
import MLTexts from "../../MLTexts"
const ml = MLTexts.getInstance();

class OrderSummary extends List {
    constructor() {
        super();
        this.state = {
            data: {},
            title: ml.getText("Zamówienie"),
            header: [
                { name: "image", title: "", field: "image", compile: true, className: "tableRowImage" },
                { name: "title", title: ml.getText("Produkt"), field: "title" },
                { name: "price", title: ml.getText("Cena"), field: "price" },
                { name: "quantity", title: ml.getText("Ilość"), field: "quantity" },
                { name: "sum", title: ml.getText("Łącznie"), field: "sum" }
            ],
            userHeader: [
                { name: "title", title: ml.getText("Kupujący"), field: "title" },
                { name: "data", title: "", field: "data" }
            ],
            userData: [],
            userDataMap: {
                "eMail": { label: ml.getText("Adres eMail") },
                "firstName": { label: ml.getText("Imię") },
                "familyName": { label: ml.getText("Nazwisko") },
                "company": { label: ml.getText("Nazwa firmy") },
                "phone": { label: ml.getText("Telefon") }
            },
            deliveryAddressHeader: [
                { name: "title", title: ml.getText("Adres dostawy"), field: "title" },
                { name: "data", title: "", field: "data" }
            ],
            deliveryAddressData: [],
            deliveryAddressMap: {
                "address": { label: ml.getText("Adres") },
                "zip": { label: ml.getText("Kod pocztowy") },
                "city": { label: ml.getText("Miasto") },
                "country": { label: ml.getText("Kraj") },
                "phone": { label: ml.getText("Telefon") }
            },
            billingAddressHeader: [
                { name: "title", title: ml.getText("Dane do faktury"), field: "title" },
                { name: "data", title: "", field: "data" }
            ],
            billingAddressData: [],
            billingAddressMap: {
                "address": { label: ml.getText("Adres") },
                "zip": { label: ml.getText("Kod pocztowy") },
                "city": { label: ml.getText("Miasto") },
                "country": { label: ml.getText("Kraj") },
                "taxID": { label: ml.getText("NIP") },
                "company": { label: ml.getText("Firma") }
            }
        }
    }


    componentDidMount() {
        let tax = 0;
        let sum = 0;
        if (this.props.data != undefined) {
            this.props.data.products.forEach((product) => {
                sum += product.price * product.quantity;
                tax += (Math.round(product.price * 100) / (100 + (product.tax))) * product.quantity;
            })

            let userData = [];
            Object.keys(this.props.data.userData).forEach(key => {
                if (this.state.userDataMap[key])
                    userData.push({ _id: key, title: this.state.userDataMap[key].label, data: this.props.data.userData[key] })
            })

            let deliveryAddressData = [];
            Object.keys(this.props.data.deliveryAddressData).forEach(key => {
                if (this.state.deliveryAddressMap[key])
                    deliveryAddressData.push({ _id: key, title: this.state.deliveryAddressMap[key].label, data: this.props.data.deliveryAddressData[key] })
            })

            let billingAddressData = [];
            Object.keys(this.props.data.billingAddressData).forEach(key => {
                if (this.state.billingAddressMap[key])
                    billingAddressData.push({ _id: key, title: this.state.billingAddressMap[key].label, data: this.props.data.billingAddressData[key] })
            })

            
            let summary = `<strong>${ml.getText("Łącznie")}:</strong> <div>${sum.toFixed(2)}</div> <br/> <strong>${ml.getText("VAT")}</strong>: <div>${(sum - tax).toFixed(2)}</div>`;
            if(this.props.data.status == "paid"){
                summary += `<br/> <strong>${ml.getText("Numer zamówienia")}:</strong> <div>${this.props.data._id}</div>`;
                summary += `<br/> <strong>${ml.getText("Data płatności")}:</strong> <div>${new Date().toLocaleString()}</div>`;
            }
            this.setState({ userData: userData, deliveryAddressData: deliveryAddressData, billingAddressData: billingAddressData, summary: summary });
        }
    }

    render() {
        return (
            <div>
                <h3 className="title">{this.state.title}</h3>
                {this.props.data ?
                    <div>
                        <Table
                            data={this.state.userData}
                            header={this.state.userHeader}
                            className="order-summary-data table"
                        />
                        <Table
                            data={this.state.deliveryAddressData}
                            header={this.state.deliveryAddressHeader}
                            className="order-summary-data table"
                        />
                        <Table
                            data={this.state.billingAddressData}
                            header={this.state.billingAddressHeader}
                            className="order-summary-data table"
                        />
                        <Table
                            data={this.props.data.products}
                            header={this.state.header}
                            className="order-summary-products table"
                        />
                        <div className="order-summary-products table">
                            <Table
                                className={"cart-summary order-summary-summary"}
                                data={[{ "suma": this.state.summary, _id: "summary" }]}
                                header={[{ name: "suma", title: !this.props.data.status || this.props.data.status =="unpaid" ? ml.getText("Kwota") : this.props.data.status =="canceled" ? ml.getText("Anulowano") : ml.getText("Opłacono"), field: "suma", className: "right", compile: true }]}
                                buttons={this.props.buttons}
                            />
                        </div>
                    </div>
                    : null}
            </div>
        )
    }
}
import ParamsHOC from "../useParamsHookHOC"
export default ParamsHOC(OrderSummary);